import { Tab } from "@headlessui/react";
import React, { Fragment, useState, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import VoyageCard from "components/VoyageCard/VoyageCard";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { compteService } from "_services/compte.service";
import { VoyageDataType } from "data/types";
import 'react-tooltip/dist/react-tooltip.css';
import AppContext from 'AppContext';
import ButtonCircleSmall from "shared/Button/ButtonCircleSmall";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import { voyageService } from '_services/voyage.service';
import { useTranslation } from 'react-i18next';

export interface MesVoyagesProps {
  className?: string;
}

const MesVoyages: FC<MesVoyagesProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  const [voyageurData, setVoyageurData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
    // Appeler l'API pour obtenir les voyages
    compteService.getVoyages()
      .then((response) => {
        setVoyageurData(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des données:", error);
        navigate('/');
      });
  }, []);

  const handleAccepterCoCreateur = (idVoyage: number, index: number) => {

    setIsLoading(true);
    voyageService.accepterCoCreateurVoyage(idVoyage)
      .then((response: any) => {

        setIsLoading(false); // Arrêter le chargement

        alert(t('alertAccepterCoCreateur'));

        window.location.reload();
        

      })
      .catch((error: any) => {
        setIsLoading(false); // Arrêter le chargement
        if (error.response?.status === 401) {
          alert("Erreur lors de l'acceptation, vous devez être connecté.");
          navigate("/login");
        } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
          console.log(error.response);
          alert(error.response.data.message);
        }
        else {
          alert("Erreur lors de l'acceptation.");
        }
        console.log('acceptation KO', error);
      });
  }

  const handleRefuserCoCreateur = (idVoyage: number, index: number) => {
    if(window.confirm(
      t('alertRefuserCoCreateur')
    ))
    {
      setIsLoading(true);
      voyageService.refuserCoCreateurVoyage(idVoyage)
        .then((response: any) => {
          // Créez une nouvelle liste de demandes en excluant l'élément à l'index
          const nouvellesDemandes = voyageurData.mesDemandes.filter((_: any, i: any) => i !== index);

          // Mettez à jour l'état avec la nouvelle liste de demandes
          setVoyageurData((prevState: any) => ({
            ...prevState,
            mesDemandes: nouvellesDemandes,
          }));
          
          setIsLoading(false); // Arrêter le chargement

        })
        .catch((error: any) => {
          setIsLoading(false); // Arrêter le chargement
          if (error.response?.status === 401) {
            alert("Erreur lors du refus, vous devez être connecté.");
            navigate("/login");
          } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
            console.log(error.response);
            alert(error.response.data.message);
          }
          else {
            alert("Erreur lors du refus.");
          }
          console.log('refus KO', error);
        });
    }
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        <div>
          <h2 className="text-2xl font-semibold">{t('voyagesCrees')}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-2 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {voyageurData && voyageurData.mesVoyages && voyageurData.mesVoyages.length > 0 ? (
                    voyageurData.mesVoyages.map((voyage: VoyageDataType) => (
                      <VoyageCard key={voyage.id_voyage} voyageData={voyage} voyageKey={voyage.id_voyage} 
                      isCreateur={voyage.createur === "X" ? true : false}
                      isCoCreateur={voyage.createur !== "X" ? true : false}/>
                    ))
                  ) : null }
                  <span className="block text-neutral-500 dark:text-neutral-400">
                    {voyageurData && voyageurData.mesVoyages && voyageurData.mesVoyages.length === 0
                    ? t('aucunVoyageCree')
                    : "" }
                  </span>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSectionCoCreateur= () => {
    return (
      <div className="listingSection__wrap [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        <div>
          <h2 className="text-2xl font-semibold">{t('mesDemandes')}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-2">
                  {voyageurData && voyageurData.mesDemandes && voyageurData.mesDemandes.length > 0 ? (
                    voyageurData.mesDemandes.map((demande: any, index: number) => (
                     <div className="mb-2">Acceptez-vous la demande de {demande.username} pour devenir co-créateur de son voyage n°{demande.id_voyage} {demande.titre_voyage} ?  <ButtonCircleSmall className="couleurVert" onClick={() => handleAccepterCoCreateur(demande.id_voyage, index)}><CheckIcon aria-hidden="true"/></ButtonCircleSmall> <ButtonCircleSmall onClick={() => handleRefuserCoCreateur(demande.id_voyage, index)} className="couleurRouge"><XMarkIcon/></ButtonCircleSmall></div>
                    ))
                  ) : null }
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSectionInfos= () => {
    return (
      <div className="listingSection__wrap [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        <div>
          <h2 className="text-xl font-semibold">Informations</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-1">
                     <div className="mb-2 text-xs sm:text-sm "><i className="las la la-warning couleurRose text-sm sm:text-lg texteGras"></i> Afin de ne pas saturer les serveurs, les documents enregistrés comme confirmations de réservation seront bientôt supprimés 1 an après la date de fin du voyage. Si vous souhaitez les conserver, penser à générer une edition PDF.</div>
                     <div className="mb-1 text-xs sm:text-sm "><span className="couleurRose text-sm texteGras">NOUVEAU : </span>Ajoutez des co-créateurs pour modifier vos voyages à plusieurs <i className="las la la-users couleurRose text-sm sm:text-lg texteGras"></i> </div>
                
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-MesVoyages ${className}`} data-nc-id="MesVoyages">
      <Helmet>
        <title>{t('mesVoyages')}</title>
      </Helmet>
      <main className="container mt-4 md:mt-8 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="w-full space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {/* Annonce Booking.com */}
          <div className=" justify-center mb-4 md:mb-0 hidden md:flex">
            <ins className="bookingaff" data-aid="2423292" data-target_aid="2423292" data-prod="banner" data-width="728" data-height="90" data-lang="fr">
              <iframe 
                src="//www.booking.com/flexiproduct.html?product=banner&amp;w=728&amp;h=90&amp;lang=fr&amp;aid=2423292&amp;target_aid=2423292&amp;tmpl=affiliate_banner&amp;fid=1719069401328&amp;" 
                width="728" 
                height="90" 
                scrolling="no" 
                style={{ order: "none", padding: 0, margin: 0, overflow: "hidden", width: "728px", height: "90px" }}
                marginHeight={0} 
                marginWidth={0} 
                frameBorder={0} 
                allowTransparency={true} 
                id="booking_widget__2423292__1719069401328" 
                data-responsive={false}
              ></iframe>
            </ins>
          </div>
          <div className="text-center mb-4 md:mb-0 hidden lg:block">
            {/* Bouton Nouveau voyage */}
            <ButtonPrimary
              sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
              fontSize="text-sm sm:text-base lg:text-lg font-medium"
              onClick={() => navigate("/")}
            >
              {t('nouveauVoyage')}
            </ButtonPrimary>
          </div> 
          { voyageurData && voyageurData.mailKo && voyageurData.mailKo === 'X' && (
            <div className="texteGras items-center text-center space-x-5 mb-6 text-xs md:text-sm lg:text-sm xl:text-sm ">
              <p><i className='las la la-warning'></i> L'email utilisé pour créer votre <a href='/account'><u>compte</u></a> est erroné, veuillez <a href='/contact'><u>nous contacter</u></a> pour le rectifier !</p>
            </div>
          )}
           {renderSectionInfos()}
           {voyageurData && voyageurData.mesDemandes && voyageurData.mesDemandes.length > 0 && (
           renderSectionCoCreateur()
           )}
          {renderSection1()}
        </div>
      </main>
      {isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              Traitement en cours, veuillez patienter...
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
  
)}
    </div>
  );
};

export default MesVoyages;
