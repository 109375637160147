import React, { FC, useEffect, useState, useContext, useCallback } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroPlanificateur from "components/SectionHeroPlanificateur/SectionHeroPlanificateur";
import Planificateur from "components/Planificateur/Planificateur";
import PlanificateurResume from "components/PlanificateurResume/PlanificateurResume";
import SectionCarte from "./SectionCarte";
import BudgetPage from "./BudgetPage";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import CommonLayout from "./CommonLayout";
import { voyageService } from '_services/voyage.service';
import { VoyagePlanificateurType, JourneeType, EventType } from "data/types";
import AppContext from 'AppContext';
import Lottie from 'react-lottie';
import animationData from '../../shared/loading.json';
import { useTranslation } from 'react-i18next';
import { useLocation} from "react-router-dom";
import moment from 'moment-timezone';

export interface PagePlanificateurProps {
  className?: string;
}

const PagePlanificateur: FC<PagePlanificateurProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const location = useLocation();
  let langue="FR";
  if (location.pathname.match(/^\/en.*$/)) {
    langue="EN";
  }

  const { setVoyageInfo, setAfficheMenuMobile, ajoutIncontournable, setAjoutIncontournable } = useContext(AppContext);
  const { voyageKey } = useParams<{ voyageKey: string }>();
  const { emailPartage } = useParams<{ emailPartage: string }>();
  const { codePartage } = useParams<{ codePartage: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [eventCategoriesPage, setEventCategoriesPage] = useState<JourneeType[]>([]);
  const [voyage, setVoyage] = useState<VoyagePlanificateurType>();
  const [menu, setMenu] = useState<string>("planning");
  const [affichageBudget, setAffichageBudget] = useState<string>("N");
  /*const [affichagePrix, setAffichagePrix] = useState<string>("N");
  const [affichagePhotos, setAffichagePhotos] = useState<string>("N");
  const [affichageLiens, setAffichageLiens] = useState<string>("N");
  const [affichageHotels, setAffichageHotels] = useState<string>("N");
  const [affichageDates, setAffichageDates] = useState<string>("N");*/
  const [fraisVisa, setFraisVisa] = useState<number>(0.0);
  const [fraisVaccins, setFraisVaccins] = useState<number>(0.0);
  const [fraisMateriel, setFraisMateriel] = useState<number>(0.0);
  const [fraisCarburant, setFraisCarburant] = useState<number>(0.0);
  const [fraisVehicule, setFraisVehicule] = useState<number>(0.0);
  const [fraisNourriture, setFraisNourriture] = useState<number>(0.0);
  const [fraisAutre, setFraisAutre] = useState<number>(0.0);
  const [etatPaiementVisa, setEtatPaiementVisa] = useState<string>("");
  const [etatPaiementVaccins, setEtatPaiementVaccins] = useState<string>("");
  const [etatPaiementMateriel, setEtatPaiementMateriel] = useState<string>("");
  const [etatPaiementCarburant, setEtatPaiementCarburant] = useState<string>("");
  const [etatPaiementVehicule, setEtatPaiementVehicule] = useState<string>("");
  const [etatPaiementNourriture, setEtatPaiementNourriture] = useState<string>("");
  const [etatPaiementAutre, setEtatPaiementAutre] = useState<string>("");
  const [infosFrais, setInfosFrais] = useState<string>("");
  const [fraisPerso, setFraisPerso] = useState<any[]>([]);
  const [mailKo, setMailKo] = useState<boolean>(false);

  const [nbLikesEdited, setNbLikesEdited] = useState<number>(0);
  const [likedEdited, setLikedEdited] = useState<string>('');

  const [isModif, setIsModif] = useState<boolean>(false);

  const [dateEntreePlanif, setDateEntreePlanif] = useState<Date>(moment().tz('Europe/Paris').toDate());

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  useEffect(() => {

      const ajouterIncontournable = async () => {
        try {
          if (ajoutIncontournable && ajoutIncontournable.libelleLieuIncontournable && ajoutIncontournable.libelleIncontournable !== "") {
            const journee = eventCategoriesPage.find((day) => day.num_jour === ajoutIncontournable.numJourIncontournable);
          
            if (journee) {
            
              const resultat = await handleAddEvenement(
                journee.id_journee,
                journee.evenements[0].id,
                ajoutIncontournable.libelleLieuIncontournable,
                "",
                ajoutIncontournable.latitudeIncontournable,
                ajoutIncontournable.longitudeIncontournable,
                ajoutIncontournable.placeIdIncontournable
              );
    
              if (resultat === 0) {
                setAjoutIncontournable(null);
                alert("Lieu ajouté à l'itinéraire !");
                // setAjoutActivitePOI(false);
                // setAjoutLieuPOI(true);
              }
            }
          }
        } catch (error) {
          console.error(error);
          setAjoutIncontournable(null);
          alert("Erreur lors de l'ajout !");
        }
      };
    
      const processAjoutIncontournable = async () => {
        
        const verrouillageResultat = await verrouillageModif();
    
        if (verrouillageResultat === 1) {
          ajouterIncontournable();
        }
      };
    
      if(ajoutIncontournable)
      {
        processAjoutIncontournable();
      }

  }, [ajoutIncontournable]);

  const handleChangeModif = () => {

    if(voyage?.etatVoyage === 1)
    {
      if(!isModif)
      {
        voyageService.verrouillerVoyage(voyage?.id_voyage)
        .then((response: any) => {
          if(response.data.message === 'ok')
          {
            if(response.data.dateVerrou !== undefined && response.data.dateVerrou !== null)
            {
              const dateVerrou = new Date(response.data.dateVerrou);

              if (dateVerrou > dateEntreePlanif) 
              {
                alert('Le voyage a pu être modifié par un co-créateur, nous allons donc actualiser la page pour prendre en compte les éventuels changements.\n\nVous pourrez ensuite reprendre votre activité.');
                window.location.reload();
              } 
              else
              {
                setIsModif(prevState => !prevState);
              }
            }
            else
            {
              setIsModif(prevState => !prevState);
            }
          }
          else
          {
            alert(response.data.alert);
          }
           
        })
        .catch((error: any) => {
          if (error.response?.status === 401) {
            alert("Erreur lors du changement de mode, vous devez être connecté.");
            navigate("/login");
          } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
            console.log(error.response);
            alert(error.response.data.message);
          }
          else {
            alert("Erreur lors du changement de mode.");
          }
          console.log('changement de mode', error);
        });
      }
      else
      {
        voyageService.deverrouillerVoyage(voyage?.id_voyage)
        .then((response: any) => {
          setIsModif(prevState => !prevState);
        })
        .catch((error: any) => {
          if (error.response?.status === 401) {
            alert("Erreur lors du changement de mode, vous devez être connecté.");
            navigate("/login");
          } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
            console.log(error.response);
            alert(error.response.data.message);
          }
          else {
            alert("Erreur lors du changement de mode.");
          }
          console.log('changement de mode', error);
        });
      }
    }
    else
    {
      setIsModif(prevState => !prevState);
    }
  }

  const handleMajLike = () => {
    setNbLikesEdited(nbLikesEdited+1);
    setLikedEdited('O');
  }

  const handleMajDislike = () => {
    setNbLikesEdited(nbLikesEdited-1);
    setLikedEdited('');
  }

  const handleAddEvenement = (idJournee: number, idEvenement: number, libelleLieu: string, description: string, latitude: string, longitude: string, placeId: string): number  => {
  
    try {

      const day = eventCategoriesPage.find((day) => day.id_journee === idJournee);
      let foundEvent: EventType | null = null;
      let eventIndex: number = -1;

      if (day) {
        const index = day.evenements.findIndex((event: EventType) => event.id === idEvenement);
    
        if (index !== -1) {
          eventIndex=index;
          foundEvent = day.evenements[index];
        } 
      }

      const type='lieu';
  
        if (foundEvent !== null && eventIndex !== null && eventIndex !== -1) {
          
          // Effectuez la requête API pour ajouter un événement
            voyageService.ajouterEvenement(voyageKey, {
                type,
                journee: idJournee,
                idEvenement: foundEvent.id,
                typeEvenement: foundEvent.typeEv
            })
          .then((response: any) => {
    
            const apiResult = response.data;

      
            // Vérifiez si la réponse de l'API est "Erreur"
            if (apiResult !== "Erreur") {
      
              setEventCategoriesPage((prevCategories) => {
                // Trouvez la journée correspondante dans la liste
                const updatedCategories = prevCategories.map((day) => {
                  if (day.id_journee === idJournee) {
                    // Créez une copie du tableau des événements
                const updatedEvenements = [...day.evenements];

                    // Ajoutez la CardLieuModifie au-dessus du bon événement
                    updatedEvenements.splice(eventIndex, 0, {
                      typeEv: 'L',
                      id: apiResult.message, // Utilisez le résultat de l'API comme ID de l'événement
                      ordre: foundEvent?.ordre || 0,
                  libelle1: "",
                  libelle2: "",
                  prix: "",
                  paiement: "",
                  lat: "",
                  longi: "",
                  place: "",
                  bloc: "",
                  type: '',
                  lien: "",
                  etat_lien: "",
                  lien_modifie: "",
                  ref: "",
                  compagnie: "",
                  debut: "",
                  fin: "",
                  lat2: "",
                  longi2: "",
                  distance: "",
                  duree: "",
                  location: '',
                  moment: '',
                  rattachement: "",
                  invisible1: "",
                  invisible2: "",
                  date_paiement_hebergement: "",
                  acompte_hebergement: "",
                  image: "",
                  document: ""
                });
    
                           // Retourner une copie de l'objet avec les événements mis à jour
                return {
                  ...day,
                  evenements: updatedEvenements,
                };
                
                        }
                        return day;
                      });
            
                      return updatedCategories;
                    });
    
                  } else {
                    console.error("Erreur lors de la requête API");
                    return -1
                    // Gérez l'erreur, affichez un message, etc.
                  }
    
                  const idNouvLieu=apiResult.message ? parseInt(apiResult.message, 10) : 0;

                  if (idNouvLieu !== 0) {
                    // Utilisez await pour attendre que modifierLieu soit terminé
                    modifierLieu(idJournee, idNouvLieu, libelleLieu, description, latitude, longitude, placeId);
                    return 0;
                  }
            })
          .catch((error: any) => {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'ajout d'évenement");
              return -1;
            }
            console.error("Erreur lors de l'ajout d'évenement:", error);
          });
      
          } 
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
      alert("Une erreur s'est produite lors de la manipulation des événements.");
      return -1;
    }
    return 0;
  };  

  const modifierLieu = (idJournee : number, idEvenementNouv : number , libelleLieu : string, description : string, latitude : string, longitude : string, placeId : string) => {

      handleElementChange(idJournee, idEvenementNouv, 'lieu', libelleLieu, 'libelle1');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', latitude, 'lat');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', longitude, 'longi');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', placeId, 'place');

      if(description !== null && description !== "")
      {
        handleElementChange(idJournee, idEvenementNouv, 'lieu', description, 'libelle2');
      }
         
      //enregistrement de la modification
      voyageService.modifierEvenement(voyageKey, {
        typeEvenement: 'lieu',
        idEvenement: idEvenementNouv,
        colonne: "libelle_lieu",
        contenu: libelleLieu,
        colonne1: 'lat_lieu',
        colonne2: 'long_lieu',
        contenu1: latitude,
        contenu2: longitude,
        placeId: placeId
      })
      .then((response: any) => {

        if(description !== null && description !== "")
        {
          //enregistrement de la modification
          voyageService.modifierEvenement(voyageKey, {
            typeEvenement: 'lieu',
            idEvenement: idEvenementNouv,
            colonne: "activites",
            contenu: description,
            conservation: false
          })
          .then((response: any) => {

            
          })
          .catch((error: any) =>
          {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'enregistrement de la modification de l'évenement");
            }
          });
        }
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
  };

  const handleElementChange = useCallback(
    (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement: string) => {

      setEventCategoriesPage((prevCategories) => {
        return prevCategories.map((day) => {
          if (day.id_journee === journeeId) {
            return {
              ...day,
              evenements: day.evenements.map((event) => {
                if (
                  (typeEvenement === 'lieu' && event.typeEv === 'L' && event.id === eventId) ||
                  (typeEvenement === 'trajet' && event.typeEv === 'T' && event.id === eventId) ||
                  (typeEvenement === 'nuit' && event.typeEv === 'N' && event.id === eventId)
                ) {
                  if (typeElement === 'libelle1') {
                    return { ...event, libelle1: valeur };
                  } else if (typeElement === 'libelle2') {
                    return { ...event, libelle2: valeur };
                  } else if (typeElement === 'prix') {
                    return { ...event, prix: valeur };
                  } else if (typeElement === 'paiement') {
                    return { ...event, paiement: valeur };
                  } else if (typeElement === 'acompte_hebergement') {
                    return { ...event, acompte_hebergement: valeur };
                  } else if (typeElement === 'date_paiement_hebergement') {
                    return { ...event, date_paiement_hebergement: valeur };
                  } else if (typeElement === 'debut') {
                    return { ...event, debut: valeur };
                  } else if (typeElement === 'fin') {
                    return { ...event, fin: valeur };
                  } else if (typeElement === 'compagnie') {
                    return { ...event, compagnie: valeur };
                  } else if (typeElement === 'ref') {
                    return { ...event, ref: valeur };
                  } else if (typeElement === 'distance') {
                    return { ...event, distance: valeur };
                  } else if (typeElement === 'duree') {
                    return { ...event, duree: valeur };
                  } else if (typeElement === 'invisible1') {
                    return { ...event, invisible1: valeur };
                  } else if (typeElement === 'invisible2') {
                    return { ...event, invisible2: valeur };
                  } else if (typeElement === 'type') {
                    return { ...event, type: valeur };
                  } else if (typeElement === 'moment') {
                    return { ...event, moment: valeur };
                  } else if (typeElement === 'rattachement') {
                    return { ...event, rattachement: valeur };
                  } else if (typeElement === 'image') {
                    return { ...event, image: valeur };
                  } else if (typeElement === 'lat') {
                    return { ...event, lat: valeur };
                  } else if (typeElement === 'longi') {
                    return { ...event, longi: valeur };
                  } else if (typeElement === 'lat2') {
                    return { ...event, lat2: valeur };
                  } else if (typeElement === 'longi2') {
                    return { ...event, longi2: valeur };
                  } else if (typeElement === 'place') {
                    return { ...event, place: valeur };
                  } else if (typeElement === 'latModif') {
                    return { ...event, latModif: valeur };
                  } else if (typeElement === 'longiModif') {
                    return { ...event, longiModif: valeur };
                  } else if (typeElement === 'lat2Modif') {
                    return { ...event, lat2Modif: valeur };
                  } else if (typeElement === 'longi2Modif') {
                    return { ...event, longi2Modif: valeur };
                  } else if (typeElement === 'document') {
                    return { ...event, document: valeur };
                  } else if (typeElement === 'lien') {
                    return { ...event, lien: valeur };
                  } else if (typeElement === 'bloc') {
                    return { ...event, bloc: valeur };
                  } else if (typeElement === 'pays') {
                    return { ...event, pays: valeur };
                  } else if (typeElement === 'nom_pays') {
                    return { ...event, nom_pays: valeur };
                  }
                }
                return event;
              }),
            };
          }
          return day;
        });
      });
    
    },
    [] // dependencies, update if needed
  );

  const handleEventCategoriesPageUpdate = (updatedCategories :any) => {

    setEventCategoriesPage(updatedCategories);
  };

  const handleFraisPageUpdate = (index: number, montantFrais: number) => {
    switch (index){
      case 0 :
        setFraisVisa(Number(montantFrais)); 
        break;
        case 1 :
          setFraisVaccins(Number(montantFrais));
          break;
        case 2 :
          setFraisMateriel(Number(montantFrais));
          break;
        case 3 :
          setFraisVehicule(Number(montantFrais));
          break;
        case 4 :
          setFraisCarburant(Number(montantFrais));
          break;
        case 5 :
          setFraisNourriture(Number(montantFrais));
          break;
        case 6 :
          setFraisAutre(Number(montantFrais));
          break;
    } 
  };

  const handleFraisPersoPageUpdate = (tabFrais: any[]) => {
    setFraisPerso(tabFrais);
  }

  const handleInfosFraisPageUpdate = (infos: string) => {
    setInfosFrais(infos);
  }

  const handleEtatFraisPageUpdate = (index: number, etatFrais: string) => {
    switch (index){
      case 0 :
          setEtatPaiementVisa(etatFrais); 
          break;
        case 1 :
          setEtatPaiementVaccins(etatFrais);
          break;
        case 2 :
          setEtatPaiementMateriel(etatFrais);
          break;
        case 3 :
          setEtatPaiementVehicule(etatFrais);
          break;
        case 4 :
          setEtatPaiementCarburant(etatFrais);
          break;
        case 5 :
          setEtatPaiementNourriture(etatFrais);
          break;
        case 6 :
          setEtatPaiementAutre(etatFrais);
          break;
    } 
  };

  useEffect(() => {
    setAfficheMenuMobile(true);
  
    if (!voyageKey) {
      // Redirect to the home page if voyageKey is not provided
      navigate("/");
    }

  }, [navigate, voyageKey]);

  
  const formattedVoyageKey = voyageKey || "";
  const formattedEmailPartage = emailPartage || "";
  const formattedCodePartage = codePartage || "";

  useEffect(() => {

    const fetchEventData = async () => {
      try {

        const response = await voyageService.getVoyage(voyageKey, formattedEmailPartage, formattedCodePartage, langue);
        const voyage = response.data.voyage;
        setVoyage(voyage);

        console.log("voyage",voyage);

        setNbLikesEdited(voyage?.nb_likes || 0);
        setLikedEdited(voyage?.liked || '');

        var cptNuitsRempl=0;
        var cptLieuxRempl=0;

        await response.data.voyage.groupedResults.forEach((day: any) => {
          day.evenements.forEach((event: any) => {
            if(event.typeEv === "N" 
            && event.libelle1 !== null && event.libelle1 !== ""
            && event.pays !== null && event.pays !== "")
            {
              cptNuitsRempl++;
            }
            else
            {
              if(event.typeEv === "L" 
              && event.libelle1 !== null 
              && event.libelle1 !== "")
              {
                cptLieuxRempl++;
              } 
            }
          })
          
        })

        var nbPays=voyage.id_pays.split('_').length;
        
        setIsModif(false);

        if((voyage.etatVoyage === 1 && voyage.num_droit === 1) || voyage.etatVoyage === 0)
        {
                  if(cptNuitsRempl === 0 && cptLieuxRempl === 0)
                  {

                    if(voyage?.etatVoyage === 1)
                    {
                        voyageService.verrouillerVoyage(voyage?.id_voyage)
                        .then((response: any) => {
                          if(response.data.message == 'ok')
                          {
                            setIsModif(true);
                          }
                          else
                          {
                            setIsModif(false);
                          }

                        })
                        .catch((error: any) => {
                          if (error.response?.status === 401) {
                            alert("Erreur, vous devez être connecté.");
                            navigate("/login");
                          } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
                            setIsModif(false);
                          }
                          else {
                            alert("Erreur lors du changement de mode.");
                          }
                        });
                    }
                    else
                    {
                      setIsModif(true);
                    }
                  }
                  else
                  {
                    const currentDate = new Date(); // Obtient la date actuelle
        
                    const formattedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    // Formatte la date actuelle au format "jj/mm/aaaa" en tant qu'objet Date
                    
                    const firstDayDateParts = voyage.date_deb_formate.split('/');
                    const firstDayYear = parseInt(firstDayDateParts[2], 10);
                    const firstDayMonth = parseInt(firstDayDateParts[1], 10) - 1;
                    const firstDayDay = parseInt(firstDayDateParts[0], 10);
                    const firstDayDate = new Date(firstDayYear, firstDayMonth, firstDayDay);
                    // Comparaison des dates
                    if (firstDayDate > formattedCurrentDate) {
                      if(voyage?.etatVoyage === 1)
                      {
                          voyageService.verrouillerVoyage(voyage?.id_voyage)
                          .then((response: any) => {
                            if(response.data.message == 'ok')
                            {
                              setIsModif(true);
                            }
                            else
                            {
                              setIsModif(false);
                            }
                          })
                          .catch((error: any) => {
                            if (error.response?.status === 401) {
                              alert("Erreur, vous devez être connecté.");
                              navigate("/login");
                            } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
                              setIsModif(false);
                            }
                            else {
                              alert("Erreur lors du changement de mode.");
                            }
                          });
                      }
                      else
                      {
                        setIsModif(true);
                      }
                    }
                    else
                    {
                      setIsModif(false);
                    }
                  }
        
                if(voyage.nb_jours>28 && cptNuitsRempl === 0 && cptLieuxRempl === 0)
                {
                  setMenu('resume');
                }
                else if(cptNuitsRempl-2>cptLieuxRempl)
                {
                    setMenu('resume');
                }
                else if(voyage.nb_jours>60 && cptNuitsRempl>((voyage.nb_jours*2)/3))
                {
                    setMenu('resume');
                }
        }
        else
        {
          if(cptNuitsRempl-2>cptLieuxRempl)
          {
            setMenu('resume');
          }
          else if(voyage.nb_jours>13 && cptNuitsRempl>((voyage.nb_jours*2)/3))
          {
            setMenu('resume');
          }
          else if(nbPays > 2 && voyage.nb_jours>7 && cptNuitsRempl>((voyage.nb_jours*2)/3))
          {
            setMenu('resume');
          }
        }

        setVoyageInfo({
          id_voyage: voyage.id_voyage, 
          num_droit: voyage.num_droit,
          nb_jours: voyage.nb_jours,
          etatVoyage: voyage.etatVoyage, 
          date_deb_formate: voyage.date_deb_formate, 
          date_fin_formate: voyage.date_fin_formate, 
          nb_voyageurs:voyage.nb_voyageurs,
          id_pays:voyage.id_pays,
          libelles_pays:voyage.libelles_pays,
          monnaie:voyage.monnaie,
          ville:voyage.ville
        });

        setAffichageBudget(voyage.affichage_budget);
        setFraisVisa(voyage.frais_visa);
        setFraisVaccins(voyage.frais_vaccins);
        setFraisVehicule(voyage.frais_vehicule);
        setFraisCarburant(voyage.frais_carburant);
        setFraisNourriture(voyage.frais_nourriture);
        setFraisMateriel(voyage.frais_materiel);
        setFraisAutre(voyage.frais_autre);
        setEtatPaiementVisa(voyage.etat_paiement_visa);
        setEtatPaiementVaccins(voyage.etat_paiement_vaccins);
        setEtatPaiementVehicule(voyage.etat_paiement_vehicule);
        setEtatPaiementCarburant(voyage.etat_paiement_carburant);
        setEtatPaiementNourriture(voyage.etat_paiement_nourriture);
        setEtatPaiementMateriel(voyage.etat_paiement_materiel);
        setEtatPaiementAutre(voyage.etat_paiement_autre);
        setInfosFrais(voyage.infos_frais);
        setFraisPerso(voyage.fraisPerso);
        setMailKo(voyage.mail_ko);

        const eventCategoriesPage = response.data.voyage.groupedResults || [];
        setEventCategoriesPage(eventCategoriesPage);
       
      } catch (error: any) {
  
        if (error.response?.status === 401) {
          localStorage.removeItem('xsrfToken');
        }
        console.error("Error fetching data:", error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
  
    fetchEventData();

  }, [voyageKey]);

  const handleMenuChange = (nomMenu: string) => {
    setMenu(nomMenu);
  };

  const verrouillageModif = async () => {
    
    if (voyage?.etatVoyage === 1) {
      console.log("voyageService");
  
      try {
        const response = await voyageService.verrouillerVoyage(voyage?.id_voyage);
        console.log("response.data.message", response.data.message);
  
        if (response.data.message === 'ok') {
          if (response.data.dateVerrou !== undefined && response.data.dateVerrou !== null) {
            const dateVerrou = new Date(response.data.dateVerrou);
            
            if (dateVerrou > dateEntreePlanif) {
              alert('Le voyage a pu être modifié par un co-créateur, nous allons donc actualiser la page pour prendre en compte les éventuels changements.\n\nVous pourrez ensuite reprendre votre activité.');
              window.location.reload();
              return 9;  // Retourne une valeur qui interrompt la suite du processus
            } else {
              console.log("return 1 -1");
              return 1;  // OK, continue
            }
          } else {
            console.log("return 1 -2");
            return 1;  // OK, continue
          }
        } else {
          alert(response.data.alert);
          setIsModif(false);  // Passer en mode consultation
          return 0;
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          alert("Erreur lors de la modification, vous devez être connecté.");
          return 9;
          navigate("/login");
        } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
          console.log(error.response);
          alert(error.response.data.message);
          return 9;
        } else {
          alert("Erreur lors de la modification.");
          return 9;
        }
        console.log('modification', error);
      }
    } else {
      return 1;  // OK, continue
    }
  };
  

    if (loading) {
    return <div className="text-center mt-20 mb-20">
      <Lottie options={defaultOptions} height={200} width={200} />
      <h1 className="text-xl">{t('chargementVoyage')}</h1>
      </div>;
  }
  return (
    <div
      className={`nc-PagePlanificateur relative`}
      data-nc-id="PagePlanificateur"
    >
      <Helmet>
        <title>Planificateur de voyages tripkygo</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="relative container-planif">
        {/* SECTION HERO */}
        <SectionHeroPlanificateur
          className="pt-3 lg:pt-4"
          idVoyage={voyage?.id_voyage || 0}
          titre={voyage?.titre_voyage || ''}
          identifiant={voyage?.identifiant || '' }
          username={voyage?.username || ''}
          avatar={voyage?.avatar || ''}
          id_pays={voyage?.id_pays || ''}
          libelles_pays={voyage?.libelles_pays || ''}
          ville={voyage?.ville || ''}
          date_deb_formate={voyage?.date_deb_formate || ''}
          date_fin_formate={voyage?.date_fin_formate || ''}
          nb_voyageurs={voyage?.nb_voyageurs || 0 }
          couverture={voyage?.couverture || ''}
          numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
          monnaie={voyage?.monnaie || "euro"}
          visibilite={voyage?.visibilite || ''}
          etatVoyage={voyage?.etatVoyage || 0}
          nbJours={voyage?.nb_jours || 0}
          isCreateur={voyage?.createur === "X" ? true : false}
          verrouillageModif={verrouillageModif}
        />

        {/* SECTION 1 */}
        <CommonLayout onClickMenu={handleMenuChange} 
                      affichageBudget={affichageBudget}
                      numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
                      menuDefaut={menu}
                      >
        <div className="relative py-5">
          <BackgroundSection largeur="background-planif"/>
          { menu === 'planning' && (
          <Planificateur
            key={`planif-${formattedVoyageKey}`} 
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
            voyageKey={formattedVoyageKey}
            emailPartage={formattedEmailPartage}
            codePartage={formattedCodePartage}
            isModif={isModif}
            mailKo={mailKo}
            onChangeModif={handleChangeModif}
            eventCategoriesPage={eventCategoriesPage}
            setEventCategoriesPage={handleEventCategoriesPageUpdate}
            id_pays={voyage?.id_pays || ''}
            nbJours={voyage?.groupedResults.length || 0}
            monnaie={voyage?.monnaie || "euro"}
            visibilite={voyage?.visibilite || ''}
            etatVoyage={voyage?.etatVoyage || 0 }
            nb_voyageurs={voyage?.nb_voyageurs || 0 }
            numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
            username={voyage?.username || '' }
            affichageBudget={affichageBudget}
            nb_likes={nbLikesEdited}
            liked={likedEdited}
            onMajLike={handleMajLike}
            onMajDislike={handleMajDislike}
            verrouillageModif={verrouillageModif}
          />
          )}

        { menu === 'resume' && (
          <PlanificateurResume
            key={`resume-${formattedVoyageKey}`} 
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
            voyageKey={formattedVoyageKey}
            emailPartage={formattedEmailPartage}
            codePartage={formattedCodePartage}
            isModif={isModif}
            mailKo={mailKo}
            onChangeModif={handleChangeModif}
            eventCategoriesPage={eventCategoriesPage}
            setEventCategoriesPage={handleEventCategoriesPageUpdate}
            id_pays={voyage?.id_pays || ''}
            libelles_pays={voyage?.libelles_pays || ''}
            nbJours={voyage?.groupedResults.length || 0}
            monnaie={voyage?.monnaie || "euro"}
            visibilite={voyage?.visibilite || ''}
            etatVoyage={voyage?.etatVoyage || 0 }
            nb_voyageurs={voyage?.nb_voyageurs || 0 }
            numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
            username={voyage?.username || '' }
            affichageBudget={affichageBudget}
            nb_likes={nbLikesEdited}
            liked={likedEdited}
            onMajLike={handleMajLike}
            onMajDislike={handleMajDislike}
            verrouillageModif={verrouillageModif}
          />
          )}

          { menu === 'carte' && (
            <div className="container-planif pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
              <SectionCarte 
              voyageKey={formattedVoyageKey}
              emailPartage={formattedEmailPartage}
              codePartage={formattedCodePartage}
              eventCategoriesPage={eventCategoriesPage}
              majEventCategoriesPage={handleEventCategoriesPageUpdate}
              etatVoyage={voyage?.etatVoyage || 0 }
              id_pays={voyage?.id_pays || ''}
              numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
              monnaie={voyage?.monnaie || 'euro'}
              nb_voyageurs={voyage?.nb_voyageurs || 0 }
              onChangeModif={handleChangeModif}
              isModif={isModif}
              verrouillageModif={verrouillageModif}
                />
            </div>
          )}

          { menu === 'budget' && (
            <div className="container-planif pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
              <BudgetPage
              voyageKey={formattedVoyageKey}
              emailPartage={formattedEmailPartage}
              codePartage={formattedCodePartage}
              monnaie={voyage?.monnaie || "euro"}
              nb_voyageurs={voyage?.nb_voyageurs || 0 }
              nb_jours={voyage?.nb_jours || 0 }
              numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
              eventCategoriesPage={eventCategoriesPage}
              setEventCategoriesPage={handleEventCategoriesPageUpdate}
              setFraisPage={handleFraisPageUpdate}
              setEtatFraisPage={handleEtatFraisPageUpdate}
              setFraisPersoPage={handleFraisPersoPageUpdate}
              setInfosFraisPage={handleInfosFraisPageUpdate}
              fraisVisa={fraisVisa}
              fraisVaccins={fraisVaccins}
              fraisMateriel={fraisMateriel}
              fraisNourriture={fraisNourriture}
              fraisCarburant={fraisCarburant}
              fraisVehicule={fraisVehicule}
              fraisAutre={fraisAutre}
              infosFrais={infosFrais}
              etatPaiementVisa={etatPaiementVisa}
              etatPaiementVaccins={etatPaiementVaccins}
              etatPaiementMateriel={etatPaiementMateriel}
              etatPaiementNourriture={etatPaiementNourriture}
              etatPaiementCarburant={etatPaiementCarburant}
              etatPaiementVehicule={etatPaiementVehicule}
              etatPaiementAutre={etatPaiementAutre}
              fraisPerso={fraisPerso}
              onChangeModif={handleChangeModif}
              isModif={isModif}
              verrouillageModif={verrouillageModif}
               />
            </div>
          )}
          
        </div>
        </CommonLayout>
      </div>
    </div>
  );
};

export default PagePlanificateur;