import React, { FC, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeadingGuides from "components/HeadingGuides/HeadingGuides";
import AppContext from 'AppContext';
import SectionVideos from "./SectionVideos";
import iconeOsano from "images/iconeOsano.png";
import iconeMarketing from "images/iconeMarketingOsano.png";


export interface PageAideProps {
  className?: string;
}

const PageAide: FC<PageAideProps> = ({ className = "" }) => {
  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);
  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []); 

  return (
    <div className={`nc-PageAide overflow-hidden relative ${className}`} data-nc-id="PageAide">
      <Helmet>
        <title>Aide</title>
      </Helmet>

      <div className="mb-24 lg:mb-32 mt-10 lg:mt-12 px-10 ml-10 mr-10">
        <div className={`nc-SectionGuides relative ${className}`}>
          <HeadingGuides desc="" isCenter={true}>
            Aide
          </HeadingGuides>
          <div className="mt-5 prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark">
            <div>
              <ol>
                <li><a href="#definitions"><u>Définitions</u></a></li>
                <li><a href="#tutoriels"><u>Tutoriels</u></a></li>
                <li><a href="#legende"><u>Légende des boutons</u></a></li>
                <li id="definitions"><a href="#faq"><u>Foire aux questions (FAQ)</u></a></li>
              </ol>
            </div>
            <div>
              <h2 >Définitions</h2>
              <ul>
                <li>
                  Voyage nomade : c'est un voyage durant lequel vous séjournez dans différentes villes (exemples : visite de la Thaïlande, tour du monde ou road-trip aux USA.). Vous devrez alors sélectionner le ou les pays visités. Si votre séjour se déroule dans une seule ville (exemples : week-end à Paris ou semaine à Majorque) alors retirez l'option sur la page de création du voyage et saisissez la ville, votre itinéraire sera pré-rempli.
                </li>
                <li  id="tutoriels">
                  Voyage public : c'est un voyage que vous avez choisi de rendre public depuis le planificateur. Il apparaît ainsi sur notre page d'accueil, vous pouvez le partager à vos amis, votre famille ou sur les réseaux sociaux et même l'intégrer à votre blog. Les confirmations de réservation que vous enregistrez dans l'outil ne sont jamais visibles par d'autres personnes que vous, tout comme le contenu de vos bagages renseigné dans l'outil de check-list bagage. Les prix, le budget, le nom des hébergements, les photos, les liens et les dates peuvent être masqués pour les autres utilisateurs que vous depuis votre profil dans l'onglet "Confidentialité voyages publics".
                </li>
              </ul>
            </div>
          </div>
            <div className="container-tutoriels mt-10">
              <SectionVideos />
            </div>
          <div className="mt-5 prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark">
        
            <div>
              <h2 id="faq">Légendes des boutons</h2>
              En préparation...
            </div>
            <div>
              <h2>FAQ</h2>
              <ul>
                <li>
                  <h4><a href="#faq1"><u>Comment partager un voyage ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq2"><u>Puis-je avoir un aperçu de la vision du voyage qu'aura la personne à qui je le partage ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq3"><u>Puis-je modifier les informations à partager avec une personne ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq4"><u>Puis-je retirer le partage du voyage à une personne ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq5"><u>Puis-je ajouter plusieurs photos pour un événement ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq6"><u>Je suis travel planner, puis-je utiliser tripkygo pour réaliser l'itinéraire de mes clients ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq7"><u>Que deviennent mes données, mes photos et mes confirmations de réservation si je supprime un voyage ou mon compte ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq8"><u>Pourquoi mon lieu n'est-il pas localisé sur la carte et ne possède-t-il pas de lien de géolocalisation sur les vues consultation et photos ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq9"><u>Pourquoi je ne trouve pas mon lieu dans la liste déroulante lors de la saisie ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq10"><u>Comment intégrer un itinéraire tripkygo sur un site/blog ?</u></a></h4>
                </li>
                <li  id="faq1">
                  <h4><a href="#faq11"><u>Puis-je consulter mon itinéraire hors ligne ou l'imprimer ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq12"><u>Pourquoi faut-il autoriser les cookies de type "Marketing" pour afficher les outils de réservation de vols, hébergements, activités,... ?</u></a></h4>
                </li>
                <li>
                  <h4><a href="#faq13"><u>Peut-on importer un itinéraire Google Maps (ou autre) dans tripkygo ?</u></a></h4>
                </li>
                <li id="faq2" >
                  <h4><a href="#faq14"><u>Peut-on exporter un itinéraire tripkygo pour l'ouvrir sur un autre site ou une autre application ?</u></a></h4>
                </li>
              </ul>
              
              <ul>
                <li>
                  <h4>Comment partager un voyage ?</h4>
                  Si votre voyage est public, vous pouvez le partager sur les réseaux sociaux grâce au bouton de partage <i className="fa-solid fa-share-nodes" style={{ color: "#fff" }}></i> présent sur le planificateur ou en partageant directement l'URL qui s'affiche dans la barre d'adresse du navigateur lorsque vous le consultez.
                  
                  S'il est privé et que vous souhaitez le partager avec des proches ou des clients, rendez-vous sur la page "Mes voyages" et cliquez sur l'icône flèche <i className="fas fa-share" style={{ color: "white", fontSize: "0.9em" }}></i> sur le voyage désiré. Vous pourrez alors choisir quelles informations partager.
                  <br/>
                  La personne recevra un email sur l'adresse que vous avez saisie et pourra accéder à votre voyage grâce à un lien sécurisé. Elle pourra également retrouver ce voyage dans son compte dans la partie "Voyages partagés" si elle crée un compte sur tripkygo avec cette adresse email ou si elle en possède déjà un.
                </li>
                
                <li id="faq3">
                  <h4>Puis-je avoir un aperçu de la vision du voyage qu'aura la personne à qui je le partage ?</h4>
                  Simulez un partage en remplissant les informations à partager et saisissez votre propre adresse email. Déconnectez-vous de tripkygo puis cliquez sur le lien sécurisé présent dans le mail que vous avez reçu. Vous avez ainsi accès à la vision qu'aura la personne avec qui vous partagerez le voyage.
                  
                  La personne pourra éditer la feuille de route en PDF mais seul le créateur du voyage peut générer le carnet de voyage avec photos.
                </li>
                
                <li id="faq4">
                  <h4>Puis-je modifier les informations à partager avec une personne ?</h4>
                  Oui, il vous suffit de faire un nouveau partage à cette personne en stipulant les nouvelles informations à partager. Elle recevra un nouveau lien et l'ancien ne fonctionnera plus.
                </li>
                
                <li id="faq5">
                  <h4>Puis-je retirer le partage du voyage à une personne ?</h4>
                  Non, cette fonctionnalité n'est, pour le moment, pas disponible. En cas de besoin, contactez-nous et nous annulerons le partage.
                </li>
                
                <li id="faq6">
                  <h4>Puis-je ajouter plusieurs photos pour un événement ?</h4>
                  Non, cette fonctionnalité n'est, pour le moment, pas disponible.
                </li>
                
                <li id="faq7">
                  <h4>Je suis travel planner, puis-je utiliser tripkygo pour réaliser l'itinéraire de mes clients ?</h4>
                  Oui, vous pouvez partager les itinéraires de vos clients en leur envoyant l'URL de votre voyage public ou en utilisant l'outil de partage privé.
                </li>
                
                <li id="faq8">
                  <h4>Que deviennent mes données, mes photos et mes confirmations de réservation si je supprime un voyage ou mon compte ?</h4>
                  Si vous supprimez un voyage, nous supprimons la totalité des données et des documents (photos et confirmations de réservations) liés à ce voyage.
<br/>Si vous supprimez votre compte, tous vos voyages sont détruits et donc la totalité des données et des documents de ceux-ci également.
</li>
                
                <li id="faq9">
                  <h4>Pourquoi mon lieu n'est-il pas localisé sur la carte et ne possède-t-il pas de lien de géolocalisation sur les vues consultation et photos ?</h4>
                  Pour afficher un lieu sur la carte et lui attribuer un lien de géolocalisation, vous devez avoir cliqué sur le lieu dans la liste déroulante qui s'affiche au moment de la saisie. C'est cette action qui nous permet de récupérer les coordonnées GPS du lieu grâce à l'API Geoapify. Si vous ne trouvez pas votre lieu dans la liste déroulante, vous pouvez saisir les cordonnnées GPS manuellement dans les options de l'évenement ou depuis la vue carte.
                </li>
                
                <li id="faq10">
                  <h4>Pourquoi je ne trouve pas mon lieu dans la liste déroulante lors de la saisie ?</h4>
                  La plupart des lieux y sont répertoriés, essayez de varier la saisie en changeant l'ordre des mots, en mettant les noms communs dans la langue du pays (lake pour lac, park pour parc,...).
Pour les aéroports, saisissez son <a href="https://fr.wikipedia.org/wiki/Liste_des_codes_IATA_des_a%C3%A9roports/A" target="_blanck" rel="noreferrer"><u>code sur 3 caractères</u></a>, son nom ou sa ville (exemple : vous trouverez l'aéroport Charles de Gaulle en saissant "CDG" ou "Charles de Gaulle" ou simplement "Paris"). </li>
                
                <li id="faq11">
                  <h4>Comment intégrer un itinéraire tripkygo sur un site/blog ?</h4>
                  1) utiliser le bouton de partage <i className="las la-share-alt"></i> présent sur le planificateur et choisir l'icone <i className="las la-code"></i>. Ou accédez aux outils <i className="las la-tools"></i> puis cliquez sur le bouton <i className="las la-code"></i><br/>2) choisir la vue par défaut à afficher sur votre site (planning, résumé, budget ou carte) en fonction des paramètres de confidentialité de voyages publics choisis dans votre profil.<br/>
                            3) copier/coller le code généré dans la page de votre site. <b>Si vous utilisez Wordpress</b>, ajoutez un bloc "HTML" et collez le code à l'intérieur.<br/>Votre fenêtre tripkygo est à présent intégrée ! <a href="https://blog.tripkygo.com/informations-inde-du-sud/" rel="noreferrer" target="_blank"><u>Exemple d'itinéraire intégré dans un article de blog</u></a><br/><br/><i  className="las la-info-circle"></i> Vous pouvez changez la hauteur de la fenêtre tripkygo en modifiant la valeur de l'attribut "height" présent dans le code généré. <br/>
                            Pour un ajustement automatique en fonction de la taille de l'écran, vous devez :<br/>1) ajouter <i> &lt;div class="responsiveWrapperTripkygo"&gt;</i> avant le code généré et <i>&lt;/div&gt;</i> à la fin du code.<br/>2) ajouter le code suivant dans votre fichier css :<br/>
                            <i>.responsiveWrapperTripkygo &#123;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    &#125;
<br/>.responsiveWrapperTripkygo iframe &#123;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &#125;</i>
                            En préparation....
                  </li>
                
                <li id="faq12">
                  <h4>Puis-je consulter mon itinéraire hors ligne ou l'imprimer ?</h4>
                  Pour consulter votre itinéraire hors ligne, vous devez le convertir en fichier PDF. Pour cela, rendez vous dans le menu "Mes voyages" et cliquez sur l'icone <i className="las fa-file-pdf"></i> sur le voyage désiré. Vous pourrez ainsi générer un fichier PDF de votre itinéraire sous deux formats "feuille de route" (facilement imprimable) ou "carnet de voyage" (incluant les photos). Vous pourrez ainsi consulter votre itinéraire même sans connexion internet.
                </li>
                
                <li id="faq13">
                  <h4>Pourquoi faut-il autoriser les cookies de type "Marketing" pour afficher les outils de réservation de vols, hébergements, activités,... ?</h4>
                  Les outils de recherche utilisés par tripkygo sont fournis par nos partenaires (Kiwi, Booking, GetYourGuide et Discovercars). Pour s'afficher ces derniers ont besoin de déposer un cookie sur votre ordinateur afin qu'ils puissent, notamment, verser une commission à tripkygo lorsque vous effectuez une réservation après avoir utilisé notre outil de réservation.<br/><br/>Pour autoriser les cookies de type "Marketing" sur le site tripkygo, veuillez :<br/>1) cliquer sur l'icone <span><img src={iconeOsano} width="30px" alt="Osano icon" className="imageOsano" /></span> en bas à droite de votre écran<br/>2) accepter les cookies de type "Marketing" <span><img src={iconeMarketing} height="20px" alt="Marketing cookies icon" className="imageMarketing" /></span><br/>3) cliquer sur "Sauvegarder" en bas de la fenêtre<br/>4) actualiser la page dans votre navigateur<br/>
                  <br/>Les outils de recherche seront désormais visibles ! </li>
                
                <li id="faq14">
                  <h4>Peut-on importer un itinéraire Google Maps (ou autre) dans tripkygo ?</h4>
                  Oui, il est possible d'importer sur tripkygo les données cartographiques d'une carte créée sur un autre site.<br/>Il vous faut d'abord exporter les données au format KML ou KMZ sur le site ou l'application où vous avez créé votre carte. Pour Google Maps, suivez les consignes de la section "Télécharger les informations de la carte" sur <a href='https://support.google.com/mymaps/answer/3109452' rel="noreferrer" target="_blank"><u>ce lien</u></a>.<br/>Une fois le fichier au format .kml ou .kmz obtenu, accédez aux outils <i className="las la-tools"></i> sur l'intinéraire où vous souhaitez faire l'import puis cliquez sur l'outil d'import <i className="las la-file-import"></i><br/> Les lieux sont importés, il ne vous reste plus qu'à sélectionner le jour et le postionnement pour les ajouter à votre itinéraire tripkygo.<br/>Si vous avez besoin d'aide, consultez ce <a href='https://youtu.be/EhkNe3TwLWg' target="_blank"><u>tutoriel</u></a>.
               </li>
                
                <li id="faq15">
                  <h4>Peut-on exporter un itinéraire tripkygo pour l'ouvrir sur un autre site ou une autre application ?</h4>
                  Oui, il est possible d'exporter un itinéraire tripkygo au format KML ou KMZ en accédant aux outils du planificateur <i className="las la-tools"></i> puis en cliquant sur le bouton d'export <i className="las la-file-export"></i> Choisissez le format désiré (KML ou KMZ) et les élements à exporter.<br/>Vous obtenez instantanément le fichier d'export que vous pouvez ensuite importer sur d'autres sites ou applications de cartographie (Google Maps, maps.me,...).<br/>Si vous avez besoin d'aide, <a href='https://youtu.be/EhkNe3TwLWg' rel="noreferrer" target="_blank"><u>tutoriel</u></a>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAide;
