import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import PlanificateurIntegre from "components/PlanificateurIntegre/PlanificateurIntegre";
import PlanificateurResumeIntegre from "components/PlanificateurResumeIntegre/PlanificateurResumeIntegre";
import SectionCarteIntegre from "./SectionCarteIntegre";
import BudgetPageIntegre from "./BudgetPageIntegre";
import React, { FC, useEffect, useState, useContext, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import CommonLayout from "./CommonLayout";
import { voyageService } from '_services/voyage.service';
import { VoyagePlanificateurType, JourneeType, EventType } from "data/types";
import AppContext from 'AppContext';
import Lottie from 'react-lottie';
import animationData from '../../shared/loading.json';
import { useTranslation } from 'react-i18next';
 
export interface PagePlanificateurIntegreProps {
  className?: string;
}

const PagePlanificateurIntegre: FC<PagePlanificateurIntegreProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  
  const { setVoyageInfo, setAfficheMenuMobile, ajoutIncontournable, setAjoutIncontournable } = useContext(AppContext);
  const { voyageKey } = useParams<{ voyageKey: string }>();
  const { vue } = useParams<{ vue: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [eventCategoriesPage, setEventCategoriesPage] = useState<JourneeType[]>([]);
  const [voyage, setVoyage] = useState<VoyagePlanificateurType>();
  const [menu, setMenu] = useState<string>(vue || 'planning');
  const [affichageBudget, setAffichageBudget] = useState<string>("N");
  const [fraisVisa, setFraisVisa] = useState<number>(0.0);
  const [fraisVaccins, setFraisVaccins] = useState<number>(0.0);
  const [fraisMateriel, setFraisMateriel] = useState<number>(0.0);
  const [fraisCarburant, setFraisCarburant] = useState<number>(0.0);
  const [fraisVehicule, setFraisVehicule] = useState<number>(0.0);
  const [fraisNourriture, setFraisNourriture] = useState<number>(0.0);
  const [fraisAutre, setFraisAutre] = useState<number>(0.0);
  const [etatPaiementVisa, setEtatPaiementVisa] = useState<string>("");
  const [etatPaiementVaccins, setEtatPaiementVaccins] = useState<string>("");
  const [etatPaiementMateriel, setEtatPaiementMateriel] = useState<string>("");
  const [etatPaiementCarburant, setEtatPaiementCarburant] = useState<string>("");
  const [etatPaiementVehicule, setEtatPaiementVehicule] = useState<string>("");
  const [etatPaiementNourriture, setEtatPaiementNourriture] = useState<string>("");
  const [etatPaiementAutre, setEtatPaiementAutre] = useState<string>("");
  const [infosFrais, setInfosFrais] = useState<string>("");
  const [fraisPerso, setFraisPerso] = useState<any[]>([]);

  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  let { mode } = useParams<{ mode: string }>();
  if(mode === undefined || mode === null || mode !== "modification")
  {
    mode="consultation";
  }

  useEffect(() => {
    setAfficheMenuMobile(true);
    if (!voyageKey) {
      // Redirect to the home page if voyageKey is not provided
      navigate("/");
    }
  }, [navigate, voyageKey]);

  

  const formattedVoyageKey = voyageKey || "";

  useEffect(() => {

    const fetchEventData = async () => {
      try {

        const response = await voyageService.getVoyageIntegre(voyageKey);
        const voyage = response.data.voyage;
        console.log("fetch voyage",voyage);
        setVoyage(voyage);

        setVoyageInfo({
          id_voyage: voyage.id_voyage, 
          num_droit: voyage.num_droit,
          nb_jours: voyage.nb_jours,
          etatVoyage: voyage.etatVoyage, 
          date_deb_formate: voyage.date_deb_formate, 
          date_fin_formate: voyage.date_fin_formate, 
          nb_voyageurs:voyage.nb_voyageurs,
          id_pays:voyage.id_pays,
          libelles_pays:voyage.libelles_pays,
          monnaie:voyage.monnaie,
          ville:voyage.ville
        });
        

        setAffichageBudget(voyage.affichage_budget);
        setFraisVisa(voyage.frais_visa);
        setFraisVaccins(voyage.frais_vaccins);
        setFraisVehicule(voyage.frais_vehicule);
        setFraisCarburant(voyage.frais_carburant);
        setFraisNourriture(voyage.frais_nourriture);
        setFraisMateriel(voyage.frais_materiel);
        setFraisAutre(voyage.frais_autre);
        setEtatPaiementVisa(voyage.etat_paiement_visa);
        setEtatPaiementVaccins(voyage.etat_paiement_vaccins);
        setEtatPaiementVehicule(voyage.etat_paiement_vehicule);
        setEtatPaiementCarburant(voyage.etat_paiement_carburant);
        setEtatPaiementNourriture(voyage.etat_paiement_nourriture);
        setEtatPaiementMateriel(voyage.etat_paiement_materiel);
        setEtatPaiementAutre(voyage.etat_paiement_autre);
        setInfosFrais(voyage.infos_frais);
        setFraisPerso(voyage.fraisPerso);

        const eventCategoriesPage = response.data.voyage.groupedResults || [];
        setEventCategoriesPage(eventCategoriesPage);


      } catch (error: any) {
    
        console.error("Error fetching data:", error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
  
    fetchEventData();

  }, [mode, voyageKey]);

  const handleMenuChange = (nomMenu: string) => {
    setMenu(nomMenu);
  };

  if (loading) {
    return <div className="text-center mt-20 mb-20">
      <Lottie options={defaultOptions} height={200} width={200} />
      <h1 className="text-xl">{t('chargementVoyage')}</h1>
      </div>;
  }

  return (
    <div
      className={`nc-PagePlanificateurIntegre relative`}
      data-nc-id="PagePlanificateurIntegre"
    >
      <Helmet>
        <title>Itinéraire créé sur le planificateur de voyages tripkygo</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative container-integre">

        {/* SECTION 1 */}
        <CommonLayout onClickMenu={handleMenuChange} 
                      affichageBudget={affichageBudget}
                      numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
                      classLayout={"layout-integre"}
                      username={voyage?.username || '' }
                      avatar={voyage?.avatar || '' }
                      vue={vue || 'planning'}
                      idVoyage={voyage?.id_voyage || 0} 
                      >
        <div className="relative py-6">
          <BackgroundSection largeur={"nolargeur"}/>
          { menu === 'planning' && (
          <PlanificateurIntegre
            key={`${mode}-${formattedVoyageKey}`} 
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
            voyageKey={formattedVoyageKey}
            emailPartage={""}
            codePartage={""}
            mode={mode}
            eventCategoriesPage={eventCategoriesPage}
            id_pays={voyage?.id_pays || ''}
            nbJours={voyage?.groupedResults.length || 0}
            monnaie={voyage?.monnaie || "euro"}
            visibilite={voyage?.visibilite || ''}
            etatVoyage={voyage?.etatVoyage || 0 }
            nb_voyageurs={voyage?.nb_voyageurs || 0 }
            numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
            username={voyage?.username || '' }
          />
          )}

        { menu === 'resume' && (
          <PlanificateurResumeIntegre
            key={`${mode}-${formattedVoyageKey}`} 
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
            voyageKey={formattedVoyageKey}
            emailPartage={""}
            codePartage={""}
            mode={mode}
            eventCategoriesPage={eventCategoriesPage}
            id_pays={voyage?.id_pays || ''}
            libelles_pays={voyage?.libelles_pays || ''}
            nbJours={voyage?.groupedResults.length || 0}
            monnaie={voyage?.monnaie || "euro"}
            visibilite={voyage?.visibilite || ''}
            etatVoyage={voyage?.etatVoyage || 0 }
            nb_voyageurs={voyage?.nb_voyageurs || 0 }
            numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
            username={voyage?.username || '' }
          />
          )}

          { menu === 'carte' && (
            <div className="containerCarteIntegre pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
              <SectionCarteIntegre 
              voyageKey={formattedVoyageKey}
              emailPartage={""}
              codePartage={""}
              eventCategoriesPage={eventCategoriesPage}
              etatVoyage={voyage?.etatVoyage || 0 }
              id_pays={voyage?.id_pays || ''}
              numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
              monnaie={voyage?.monnaie || 'euro'}
              nb_voyageurs={voyage?.nb_voyageurs || 0 }
                />
            </div>
          )}

          { menu === 'budget' && (
            <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
              <BudgetPageIntegre
              voyageKey={formattedVoyageKey}
              emailPartage={""}
              codePartage={""}
              monnaie={voyage?.monnaie || "euro"}
              nb_voyageurs={voyage?.nb_voyageurs || 0 }
              nb_jours={voyage?.nb_jours || 0 }
              numDroit={voyage?.num_droit ? voyage?.num_droit : voyage?.num_droit === 0 ? 0 : 999}
              eventCategoriesPage={eventCategoriesPage}
              fraisVisa={fraisVisa}
              fraisVaccins={fraisVaccins}
              fraisMateriel={fraisMateriel}
              fraisNourriture={fraisNourriture}
              fraisCarburant={fraisCarburant}
              fraisVehicule={fraisVehicule}
              fraisAutre={fraisAutre}
              infosFrais={infosFrais}
              etatPaiementVisa={etatPaiementVisa}
              etatPaiementVaccins={etatPaiementVaccins}
              etatPaiementMateriel={etatPaiementMateriel}
              etatPaiementNourriture={etatPaiementNourriture}
              etatPaiementCarburant={etatPaiementCarburant}
              etatPaiementVehicule={etatPaiementVehicule}
              etatPaiementAutre={etatPaiementAutre}
              fraisPerso={fraisPerso}
               />
            </div>
          )}
        </div>
        </CommonLayout>
      </div>
    </div>
  );
};

export default PagePlanificateurIntegre;