import React, { useState, useEffect, useContext } from "react";
import GuestsInput from "../GuestsInput";
import SelectMonnaie from "../SelectMonnaie";
import VilleInputMobile, { Destination } from "./VilleInputMobile";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { useTranslation } from 'react-i18next';
import AppContext from 'AppContext';

interface StaySearchFormProps {
  onSelectedDestinationsChange: (destinations: Destination[]) => void;
  onStartDateChange: (startDate: Date | null) => void;
  onEndDateChange: (endDate: Date | null) => void;
  onGuestAdultsInputValueChange: (guestAdultsInputValue: number) => void;
  onMonnaieChange: (monnaieValue: string) => void;
  initDest: Destination[] | null;
  initDateDeb: Date | null;  
  initDateFin: Date | null;  
  initNbVoyageurs: number | null;
  initMonnaie: string | null;
  isPlanif: boolean;
  numDroit: number;
} 

const StaySearchForm: React.FC<StaySearchFormProps> = ({
  onSelectedDestinationsChange,
  onStartDateChange,
  onEndDateChange,
  onGuestAdultsInputValueChange,
  onMonnaieChange,
  initDest,
  initDateDeb, 
  initDateFin, 
  initNbVoyageurs,
  initMonnaie,
  isPlanif,
  numDroit
}) => {
  const { t } = useTranslation();
  const { voyageInfo } = useContext(AppContext);

  const today=new Date();

  const [selectedDestinations, setSelectedDestinations] = useState<Destination[]>(initDest || []);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [monnaie, setMonnaie] = useState<string | null>("euro");
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "location" | "dates" | "guests" | "monnaie"
  >("location");
  //
  const [locationInputTo, setLocationInputTo] = useState("");

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: initNbVoyageurs || 1,
    guestChildren: 0,
    guestInfants: 0,
  });

  //

  useEffect(() =>{
    setStartDate(initDateDeb || new Date());
    setEndDate(initDateFin || new Date());
    setMonnaie(initMonnaie || "euro");
  }, [initDateDeb, initDateFin] );


  useEffect(() => {
    const guest =
    (guestInput.guestAdults || 1) + (guestInput.guestChildren || 0);
    onGuestAdultsInputValueChange(guest);
  }, [guestInput]);

  const handleMonnaieChange = (monnaieValue: string) => {
    onMonnaieChange(monnaieValue);
    setMonnaie(monnaieValue);
  }

  const recupSymboleMonnaie = (libMonnaie: string) => {
    var monnaieSymb="";
    switch(libMonnaie) {
      case 'euro':
        monnaieSymb='€';
        break;
      case 'dollar':
       monnaieSymb='$';
        break;
      case 'pound':
       monnaieSymb='£';
          break;
      case 'yen':
       monnaieSymb='¥';
            break;
      case 'won':
       monnaieSymb='₩';
              break;
      case 'rupee':
       monnaieSymb='₹';
            break;
            case 'indian-rupee':
             monnaieSymb='₹';
                break;
            case 'baht':
             monnaieSymb='฿';
                break;
            case 'brazilian-real':
             monnaieSymb='R$';
                break;
            case 'dong':
             monnaieSymb='₫';
                break;
            case 'peso':
             monnaieSymb='₱';
                break;
            case 'ruble':
             monnaieSymb='₽';
                break;
                case 'chf':
                 monnaieSymb='CHF';
                    break;
      default:
       monnaieSymb='€';
        
      }
      return monnaieSymb;
  }

  const handleSelectDestination = (codePays: string, pays: string, ville: string, place_id: string, latitude: string, longitude: string) => {
    const selectedDestination: Destination = {
      code_pays: codePays,
      pays: pays,
      ville: ville,
      place_id: place_id,
      latitude: latitude,
      longitude: longitude,
    };
  
    setSelectedDestinations([selectedDestination]);
    setFieldNameShow("dates");
  };

  
  useEffect(() => {
    onSelectedDestinationsChange(selectedDestinations);

  }, [selectedDestinations]); 

  const handleRemoveDestination = (index:number) => {
    const updatedDestinations = [...selectedDestinations];
    updatedDestinations.splice(index, 1);
    setSelectedDestinations(updatedDestinations);
};
  
  const handleSelectDates = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onStartDateChange(start);
    onEndDateChange(end);
  };

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive && (numDroit === 1 || numDroit === 0 || !isPlanif)
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >

        {!isActive || isPlanif ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={!isPlanif ? () => setFieldNameShow("location") : numDroit === 0 || numDroit === 1 ? () => alert("Vous ne pouvez pas modifier la destination d'un voyage sédentaire") :  () => null}
          >
            <span className="text-neutral-400">Destination{selectedDestinations.length > 1 ? "s" : ""}</span>
            <span>{selectedDestinations.length > 0 
    ? selectedDestinations.map((dest: Destination) => dest.ville+", "+dest.pays).join(" - ")
    : t('aucune')}</span>
          </button>
        ) : (
          <VilleInputMobile
          className="flex-[1.5]"
          onSelectDestination={handleSelectDestination}
          destinationsEnregistrees={selectedDestinations}
       />
          )}

      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive && (numDroit === 1 || numDroit === 0 || !isPlanif)
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive || (isPlanif && numDroit !== 1 && numDroit !== 0) ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">Dates</span>
            <span>
            {startDate && endDate ? (
              voyageInfo &&
              today.getDay() === startDate.getDay() &&
              today.getDay() === endDate.getDay() &&
              today.getMonth() === startDate.getMonth() &&
              today.getMonth() === endDate.getMonth() &&
              today.getFullYear() === startDate.getFullYear() &&
              today.getFullYear() === endDate.getFullYear()
            ) ? (
              t('masquees')
            ) : (
              converSelectedDateToString([startDate, endDate])
            ) : (
              t('ajoutezDates')
            )}
            </span>
          </button>
        ) : (
          <DatesRangeInput onSelectDates={handleSelectDates} 
          initDeb={initDateDeb}
          initFin={initDateFin}
          majDeb={startDate}
          majFin={endDate}/>
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 1) + (guestInput.guestChildren || 0);
        const guestLabel = guest === 1 ? t('voyageurMin') : t('voyageurMin') +"s";
        guestSelected += `${guest} ${guestLabel}`;
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive && (numDroit === 1 || numDroit === 0 || !isPlanif)
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive || (isPlanif && numDroit !== 1 && numDroit !== 0) ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">{t('nombreVoyageurs')}</span>
            <span>{guestSelected || t('modifier')}</span>
          </button>
        ) : (
          <GuestsInput defaultValue={guestInput} onChange={setGuestInput} />
        )}
      </div>
    );
  };

  const renderMonnaie = () => {
    const isActive = fieldNameShow === "monnaie";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 1) + (guestInput.guestChildren || 0);
        const guestLabel = guest === 1 ? t('voyageurMin') : t('voyageurMin')+"s";
        guestSelected += `${guest} ${guestLabel}`;
        onGuestAdultsInputValueChange(guest);
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive && (numDroit === 1 || numDroit === 0 || !isPlanif)
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive || (isPlanif && numDroit !== 1 && numDroit !== 0) ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("monnaie")}
          >
            <span className="text-neutral-400">{t('monnaie')}</span>
            <span>{recupSymboleMonnaie(monnaie || "euro") || t('modifier')}</span>
          </button>
        ) : (
          <SelectMonnaie defaultValue={monnaie || "euro"} onChange={handleMonnaieChange} />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
        {/*  */}
        { isPlanif && (
          renderMonnaie()
        )}
      </div>
    </div>
  );
};

export default StaySearchForm;
