import React, { FC, Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import SelectPaiementHebergement from "shared/SelectPaiementHebergement/SelectPaiementHebergement";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { EventType, GeoapifyType, JourneeType } from "data/types";
import {apiKeyGeoapify} from '_config/constantes'; 
import AutoCompleteList from "components/AutocompleteList";
import { useNavigate } from "react-router-dom";
import { voyageService } from "../_services/voyage.service";
import { useTranslation } from 'react-i18next';

export interface ModalModifResumeProps {
  onModifResumeCloseModal: () => void;
  nuits: EventType[];
  date: string;
  idDate: number;
  listeNomPays: string;
  listeIdPays: string;
  pays: string;
  voyageKey: string;
  eventCategories: JourneeType[];
  onDisplayElementChange?: (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement:string) => void;
}

const ModalModifResume: FC<ModalModifResumeProps> = ({
  onModifResumeCloseModal,
  nuits,
  date,
  idDate,
  listeNomPays,
  listeIdPays,
  pays,
  voyageKey,
  eventCategories,
  onDisplayElementChange
}) => {
  const { t } = useTranslation();
  
  let navigate=useNavigate();

  const suggestionSelectedRef = useRef(false);
  const [oldDisplayName, setOldDisplayName] = useState("");
  const [editedDisplayName, setEditedDisplayName] = useState("");
  const [editedLatitude, setEditedLatitude] = useState("");
  const [editedLongitude, setEditedLongitude] = useState("");
  const [lang, setLang] = useState<string>("fr")
  const [suggestions, setSuggestions] = useState<GeoapifyType[]>([]);
  const [listePays, setListePays] = useState<string>("");
  const [codePays, setCodePays] = useState<string>(pays);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<number>(0);
  const [selectedNbNuits, setSelectedNbNuits] = useState<number>(nuits.length);
  const divAutocompleteRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const oldInputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const selectNbNuitsRef = useRef<HTMLSelectElement>(null);
  const selectDateRef = useRef<HTMLSelectElement>(null);
  const [texteInformatif, setTexteInformatif] = useState<string>("");
  const [tableauInfo, setTableauInfo] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        divAutocompleteRef.current &&
        !divAutocompleteRef.current.contains(event.target as Node)
      ) {
          setIsModalOpen(false);
          const currentInputValue = inputRef.current?.value || "";
          const oldInputValue = oldInputRef.current?.value || ""; 

          if( currentInputValue.replace(/ /g, "")  !== oldInputValue.replace(/ /g, ""))
          {
            setCodePays("");
            setEditedLatitude("");
            setEditedLongitude("");
            setOldDisplayName(currentInputValue);
            
            if(currentInputValue === null || currentInputValue === "")
            {
              setCodePays("");
              setTexteInformatif(t('villeNonRenseignee'));
              setEditedLatitude("");
              setEditedLongitude("");
              setTableauInfo([]);
            }
            else
            {
              setTexteInformatif(t('paysNonRenseigneCliquez'));

              var result=calculeIndicateurs(Number(selectDateRef.current?.value), Number(selectNbNuitsRef.current?.value));

              if(currentInputValue !== "")
              {

                var dateDebutPer1="";
                var dateFinPer1="";
                var dateDebutPer2="";
                var dateFinPer2="";
                var dateDebutPer3="";
                var dateFinPer3="";
                var periode1="";
                var periode2="";
                var periode3="";
                var libellePays="";
                var tableauInformations: string[]=[];

                if(result.dateReculee && result.nbNuitsDimin)
                {
                  var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
                  
                  if (indice1 !== -1) {
                    dateDebutPer1 = addDays(date, indice1);
                  }

                  var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
                  
                  if (indice2 !== -1) {
                    dateFinPer1 = addDays(date, indice2);
                    dateDebutPer2 = addDays(date, indice2+1);
                  }

                  var indice3=nuits.findIndex((item: any) => item.id === result.numNuitDebEff2);
                  
                  if (indice3 !== -1) {
                    dateFinPer2 = addDays(date, indice3-1);
                    dateDebutPer3 = addDays(date, indice3);
                  }

                  var indice4=nuits.findIndex((item: any) => item.id === result.numNuitFinEff2);
                  
                  if (indice4 !== -1) {
                    dateFinPer3 = addDays(date, indice4);
                  }

                  libellePays=" - "+t('paysNonRenseigne');

                  tableauInformations.push(t('situationApresEnregistrement'));

                  if(dateDebutPer1 !== dateFinPer1)
                  {
                    periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  {
                    periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
                  }

                  if(dateDebutPer2 !== dateFinPer2)
                  {
                    periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+currentInputValue+libellePays;
                  }
                  else
                  {
                    periode2=dateDebutPer2+" : "+currentInputValue+libellePays;
                  }

                  if(dateDebutPer3 !== dateFinPer3)
                  {
                    periode3=t('du')+" "+dateDebutPer3+" "+t('au')+" "+dateFinPer3+" : "+(indice4-indice3+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  { 
                    periode3=dateDebutPer3+" : 1 "+t('nuit')+" "+t('liberee');
                  }
                  
                  tableauInformations.push(periode1);
                  tableauInformations.push(periode2);
                  tableauInformations.push(periode3);

                  setTableauInfo(tableauInformations);

                }
                else if(result.dateReculee && !result.nbNuitsDimin)
                {
                  var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
                  
                  if (indice1 !== -1) {
                    dateDebutPer1 = addDays(date, indice1);
                  }

                  var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
                  
                  if (indice2 !== -1) {
                    dateFinPer1 = addDays(date, indice2);
                    dateDebutPer2 = addDays(date, indice2+1);
                    dateFinPer2 = addDays(date, nuits.length-1);
                  }

                  libellePays=" - "+t('paysNonRenseigne');

                  tableauInformations.push(t('situationApresEnregistrement'));

                  if(dateDebutPer1 !== dateFinPer1)
                  {
                    periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  {
                    periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
                  }

                  if(dateDebutPer2 !== dateFinPer2)
                  {
                    periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+currentInputValue+libellePays;
                  }
                  else
                  {
                    periode2=dateDebutPer2+" : "+currentInputValue+libellePays;
                  }
                  
                  tableauInformations.push(periode1);
                  tableauInformations.push(periode2);

                  setTableauInfo(tableauInformations);
                  
                }
                else if(!result.dateReculee && result.nbNuitsDimin)
                {
                  dateDebutPer1 = date;

                  var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
                  
                  if (indice1 !== -1) {
                    dateFinPer1 = addDays(date, indice1-1);
                    dateDebutPer2 = addDays(date, indice1);
                  }

                  var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
                  
                  if (indice2 !== -1) {
                    dateFinPer2 = addDays(date, indice2);
                  }

                  libellePays=" - "+t('paysNonRenseigne');

                  tableauInformations.push(t('situationApresEnregistrement'));

                  if(dateDebutPer1 !== dateFinPer1)
                  {
                    periode2=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+currentInputValue+libellePays;
                  }
                  else
                  {
                    periode2=dateDebutPer1+" : "+currentInputValue+libellePays;
                  }

                  if(dateDebutPer2 !== dateFinPer2)
                  {
                    periode3=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  {
                    periode3=dateDebutPer2+" : 1 "+t('nuit')+" "+t('liberee');
                  }

                  
                  tableauInformations.push(periode2);
                  tableauInformations.push(periode3);

                  setTableauInfo(tableauInformations);
                }
                else
                {
                  setTableauInfo([]);
                }
              }
            }
          }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setEditedDisplayName(nuits[0].libelle1 || "");
    setOldDisplayName(nuits[0].libelle1 || "");
    setSelectedNbNuits(nuits.length);
    setSelectedDate(0);
    setEditedLatitude(nuits[0].lat || "");
    setEditedLongitude(nuits[0].longi || "");

    if(nuits[0].libelle1 ===  null || nuits[0].libelle1 === "")
    {
      setTexteInformatif(t('villeNonRenseignee'));
    }
    else if(codePays === "")
    {
      setTexteInformatif(t('paysNonRenseigneCliquez'));
    }
  }, [nuits]);


  useEffect(() => {
    
    var alphabetDiff=false;
  
    if(listeIdPays !== null)
    {
          var tab=listeIdPays.split('_');
  
          for(var i= 0; i < tab.length; i++)
          {
              if(tab[i].toLowerCase() === 'ww' || tab[i].toLowerCase() === 'xx' || tab[i].toLowerCase() === 'yy' || tab[i].toLowerCase() === 'zz')
              {
                  tab[i]='gb';
              }
              else if(tab[i].toLowerCase() === 'yt' || tab[i].toLowerCase() === 'gf' || tab[i].toLowerCase() === 'pf' || tab[i].toLowerCase() === 'tf' 
                   || tab[i].toLowerCase() === 'gp' || tab[i].toLowerCase() === 'mq' || tab[i].toLowerCase() === 're' || tab[i].toLowerCase() === 'pm'
                   || tab[i].toLowerCase() === 'wf')
              {
                  tab[i]='fr';
              }
              else if(tab[i].toLowerCase() === 'as' || tab[i].toLowerCase() === 'mp' || tab[i].toLowerCase() === 'vi')
              {
                  tab[i]='us';
              }
              else if(tab[i].toLowerCase() === 'bv' || tab[i].toLowerCase() === 'sj')
              {
                  tab[i]='no';
              }
              else if(tab[i].toLowerCase() === 'cc' || tab[i].toLowerCase() === 'hm' || tab[i].toLowerCase() === 'nf')
              {
                  tab[i]='au';
              }
          }
  
        var listePaysAlphabetDiff=['hk','af','am','bh','bd','by','bt','mm','bg','kh','cn','cy','eg','er','ge','gr','in','ir','iq','il','jp','jo','kz','kw','kg','la','ly','mk','mr','mn','ma','np','kp','om','pk','qa','ru','sa','cs','kr','lk','sd','sy','tj','tw','th','tn','ua','ye','ae','ps','mo','rs'];
       
        switch (tab.length) {
            case 1:
              setListePays(tab[0].toLowerCase());
              if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1)
              {
                alphabetDiff=true;
              }
              break;
            case 2:
              setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase());
              if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1)
              {
                alphabetDiff=true;
              }
              break;
            case 3:
              setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase());
              if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1)
              {
                alphabetDiff=true;
              }
              break;
            case 4:
              setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase());
              if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1)
              {
                alphabetDiff=true;
              }
              break;
            case 5:
              setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase()+','+tab[4].toLowerCase());
              if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[4].toLowerCase()) > -1)
              {
                alphabetDiff=true;
              }
              break;
            default:
              setListePays("");
              alphabetDiff=true;
          }
          if(listePays.includes("undefined"))
          {
            setListePays("");
            alphabetDiff=true;
          }
      }
  
      if(alphabetDiff)
      {
        setLang("en");
      }
      else
      {
        setLang("fr");
      }
  }, [listeIdPays]);

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setEditedDisplayName(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (event.target.value !== "" && event.target.value.length > 2) {
      const id = setTimeout(() => {
        fetchSuggestions(event.target.value, '');
      }, 1000);
  
      setTimeoutId(id);
    } else {
      // Si la valeur est vide ou trop courte, effacer les suggestions
      setSuggestions([]);
    }
  };

    
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  
  const fetchSuggestions = async (value: string, type: string) => {

    const limit = 7; // Limite de suggestions

    var filter=listePays;
  

    var complementUrl = `&filter=countrycode:${filter}&bias=countrycode:${listePays}`;
    
    if(listePays.length === 0)
    {
      complementUrl = "";
    }

    if(type === 'heb')
    {
      complementUrl+='&type=amenity';
    }

    try {
      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&lang=${lang}&limit=${limit}&apiKey=${apiKeyGeoapify}${complementUrl}`
      );

      if (response.ok) {
        const data = await response.json();
        if(type === '')
        {
          setSuggestions(data.features);
          setIsModalOpen(true);
        }
      }
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
    }
  };

  const onPlaceChangedVille = (place: GeoapifyType) => {

    var latitude = place.properties.lat;
    var longitude = place.properties.lon; 

    var libelleVille="";

    if(place.properties.name !== undefined && place.properties.name !== null)
    {
      libelleVille=place.properties.name;
    }
    else if(place.properties.city !== undefined && place.properties.city !== null)
    {
      libelleVille=place.properties.city;
    }
    else if(place.properties.state !== undefined && place.properties.state !== null)
    {
      libelleVille=place.properties.state;
    }
    else if(place.properties.country !== undefined && place.properties.country !== null)
    {
      libelleVille=place.properties.country;
    }
    else
    {
      libelleVille=place.properties.formatted;
    }

var paysChanged="";
if(place.properties.country_code !== null)
{
  if(place.properties.country_code === 'gb')
  {
    if(place.properties.state !== null)
    {  
      switch (place.properties.state) {
                    case 'Angleterre':
                      paysChanged = 'ww';
                      break;
                    case 'Écosse':
                      paysChanged = 'xx';
                      break;
                    case 'Irlande du Nord':
                      paysChanged = 'yy';
                      break;
                    case 'Pays de Galles':
                      paysChanged = 'zz';
                      break;
                    case 'England':
                      paysChanged = 'ww';
                      break;
                    case 'Scotland':
                      paysChanged = 'xx';
                      break;
                    case 'Northern Ireland':
                      paysChanged = 'yy';
                      break;
                    case 'Wales':
                      paysChanged = 'zz';
                      break;
                    default:
                      paysChanged=place.properties.country_code;
                }
    }
    else
    {
      paysChanged=place.properties.country_code;
    }
  }
  else if(place.properties.country_code === 'fr')
  {
    if(place.properties.state !== null)
    {
              
      switch (place.properties.state) {
          case 'Guadeloupe':
            paysChanged = 'gp';
            break;
          case 'Martinique':
            paysChanged = 'mq';
            break;
          case 'Réunion':
            paysChanged = 're';
            break;
          case 'Mayotte':
            paysChanged = 'yt';
            break;
          case 'Guyane':
              paysChanged = 'gf';
              break;
          case 'Guyane Française':
            paysChanged = 'gf';
            break;
          case 'Polynésie Française':
            paysChanged = 'pf';
            break;
          case 'Terres Australes Françaises':
            paysChanged = 'tf';
            break;
          case 'Saint-Pierre-et-Miquelon':
            paysChanged = 'pm';
            break;
          case 'Wallis et Futuna':
            paysChanged = 'wf';
            break;
          default:
          paysChanged=place.properties.country_code;
        }
    }
    else
    {
      paysChanged=place.properties.country_code;
    }
  }
  else
  {
    paysChanged=place.properties.country_code;
  }
}
    setEditedLatitude(latitude);
    setEditedLongitude(longitude);
    setEditedDisplayName(libelleVille);
    setOldDisplayName(libelleVille);

    setCodePays(paysChanged);
    if(paysChanged === "")
    {
      setTexteInformatif(t('paysNonRenseigneCliquez'));
    }
    else
    {
      setTexteInformatif("");
    }

    var result=calculeIndicateurs(selectedDate, selectedNbNuits);

    if(libelleVille !== "")
    {

      var dateDebutPer1="";
      var dateFinPer1="";
      var dateDebutPer2="";
      var dateFinPer2="";
      var dateDebutPer3="";
      var dateFinPer3="";
      var periode1="";
      var periode2="";
      var periode3="";
      var libellePays="";
      var tableauInformations: string[]=[];

      if(result.dateReculee && result.nbNuitsDimin)
      {
        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateDebutPer1 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer1 = addDays(date, indice2);
          dateDebutPer2 = addDays(date, indice2+1);
        }

        var indice3=nuits.findIndex((item: any) => item.id === result.numNuitDebEff2);
        
        if (indice3 !== -1) {
          dateFinPer2 = addDays(date, indice3-1);
          dateDebutPer3 = addDays(date, indice3);
        }

        var indice4=nuits.findIndex((item: any) => item.id === result.numNuitFinEff2);
        
        if (indice4 !== -1) {
          dateFinPer3 = addDays(date, indice4);
        }

        if(paysChanged !== "")
        {
          libellePays=" - "+listeNomPays.split("_")[listeIdPays.split("_").map(item => item.toLowerCase()).indexOf(paysChanged.toLowerCase())];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+libelleVille+libellePays;
        }
        else
        {
          periode2=dateDebutPer2+" : "+libelleVille+libellePays;
        }

        if(dateDebutPer3 !== dateFinPer3)
        {
          periode3=t('du')+" "+dateDebutPer3+" "+t('au')+" "+dateFinPer3+" : "+(indice4-indice3+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        { 
          periode3=dateDebutPer3+" : 1 "+t('nuit')+" "+t('liberee');
        }
        
        tableauInformations.push(periode1);
        tableauInformations.push(periode2);
        tableauInformations.push(periode3);

        setTableauInfo(tableauInformations);

      }
      else if(result.dateReculee && !result.nbNuitsDimin)
      {
        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateDebutPer1 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer1 = addDays(date, indice2);
          dateDebutPer2 = addDays(date, indice2+1);
          dateFinPer2 = addDays(date, nuits.length-1);
        }

        if(paysChanged !== "")
        {
          libellePays=" - "+listeNomPays.split("_")[listeIdPays.split("_").map(item => item.toLowerCase()).indexOf(paysChanged.toLowerCase())];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+libelleVille+libellePays;
        }
        else
        {
          periode2=dateDebutPer2+" : "+libelleVille+libellePays;
        }
        
        tableauInformations.push(periode1);
        tableauInformations.push(periode2);

        setTableauInfo(tableauInformations);
        
      }
      else if(!result.dateReculee && result.nbNuitsDimin)
      {
        dateDebutPer1 = date;

        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateFinPer1 = addDays(date, indice1-1);
          dateDebutPer2 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer2 = addDays(date, indice2);
        }

        if(paysChanged !== "")
        {
          libellePays=" - "+listeNomPays.split("_")[listeIdPays.split("_").map(item => item.toLowerCase()).indexOf(paysChanged.toLowerCase())];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode2=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+libelleVille+libellePays;
        }
        else
        {
          periode2=dateDebutPer1+" : "+libelleVille+libellePays;
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode3=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode3=dateDebutPer2+" : 1 "+t('nuit')+" "+t('liberee');
        }

        
        tableauInformations.push(periode2);
        tableauInformations.push(periode3);

        setTableauInfo(tableauInformations);
      }
      else
      {
        setTableauInfo([]);
      }
    }
};

  const calculeIndicateurs = (indexDateSel: number, nbNuits: number) => {

    var dateReculee=false;
    var nbNuitsDimin=false;
    var libelleModif=false;
    var paysModif=false;
    var paysSuppr=false;

    //Changement date
    if(indexDateSel !== 0)
    {
      dateReculee=true;
    }

    var numNuit=nuits[indexDateSel].id;

    //Changement nb jours
    if(nbNuits+indexDateSel < nuits.length)
    {
      nbNuitsDimin=true;
    }

    //Changement ville
    if( editedDisplayName.replace(/ /g, "")  !== nuits[0].libelle1?.replace(/ /g, ""))
    {
      libelleModif=true;
    }

    //Changement pays
    if(codePays !== '' && codePays  !== pays)
    {
      paysModif=true;
    }

    //Suppression pays
    if(pays !== '' && codePays  === '')
    {
      paysSuppr=true;
    }

    var numNuitDebEff1=0;
    var numNuitFinEff1=0;
    var numNuitDebEff2=0;
    var numNuitFinEff2=0;

    if(dateReculee || nbNuitsDimin)
    {
      //Modification dans les dates
      if(dateReculee && nbNuitsDimin)
      {
        numNuitDebEff1=nuits[0].id;
        numNuitFinEff1=nuits[indexDateSel-1].id;
        numNuitDebEff2=nuits[indexDateSel+nbNuits].id;
        numNuitFinEff2=nuits[nuits.length-1].id;
      }
      else if(dateReculee && !nbNuitsDimin)
      {
        numNuitDebEff1=nuits[0].id;
        numNuitFinEff1=nuits[indexDateSel-1].id;
      }
      else if(!dateReculee && nbNuitsDimin)
      {
        numNuitDebEff1=nuits[indexDateSel+nbNuits].id;
        numNuitFinEff1=nuits[nuits.length-1].id;
      }
  
    }

    return { libelleModif, paysModif, paysSuppr, dateReculee, nbNuitsDimin, numNuit, nbNuits, numNuitDebEff1, numNuitFinEff1, numNuitDebEff2, numNuitFinEff2};

  }

  const handleSelectSuggestion = (suggestion: GeoapifyType) => {
    suggestionSelectedRef.current = true;
    onPlaceChangedVille(suggestion);
    setSuggestions([]);
  };

  function addDays(dateString: string, daysToAdd: number) {
    // Split the input date string to get day, month, and year
    const [day, month, year] = dateString.split("/");
  
    // Create a new Date object
    const date = new Date(`${year}-${month}-${day}`);
  
    // Get the current timestamp and add the required number of days
    const newTimestamp = date.getTime() + (daysToAdd * 24 * 60 * 60 * 1000);
  
    // Create a new Date from the updated timestamp
    const newDate = new Date(newTimestamp);
  
    // Format the date back to dd/mm/yyyy
    const newDay = String(newDate.getDate()).padStart(2, '0'); // Ensure two digits for day
    const newMonth = String(newDate.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month (months are 0-based)
    const newYear = newDate.getFullYear(); // Get the year
  
    // Return the formatted date
    return `${newDay}/${newMonth}/${newYear}`;
  }

  const handleModifierNuits = () => {
   
      if(editedDisplayName.replace(/ /g, "")  === "")
      {
          alert('Vous devez saisir la ville où vous séjournerez !');
          inputRef.current?.focus();
      }
      else
      {
        setIsLoading(true);

        var result=calculeIndicateurs(Number(selectDateRef.current?.value), Number(selectNbNuitsRef.current?.value));

   //enregistrement de la modification
   voyageService.modifierNuits(voyageKey, {
      numNuit: result.numNuit,
      nbNuits: result.nbNuits,
      libelleModif: result.libelleModif,
      libelleVille: editedDisplayName,
      latitude: editedLatitude,
      longitude: editedLongitude,
      paysModif: result.paysModif,
      idPays: codePays,
      paysSuppr: result.paysSuppr,
      nbNuitsDimin: result.nbNuitsDimin,
      dateReculee: result.dateReculee,
      numNuitDebEff1: result.numNuitDebEff1,
      numNuitFinEff1: result.numNuitFinEff1,
      numNuitDebEff2: result.numNuitDebEff2,
      numNuitFinEff2: result.numNuitFinEff2,
    })
    .then((response: any) => {
      var numeroNuit=response.data.numeroNuit;
      var maxNuit=response.data.maxNuit;
      var numeroNuitDebEff1=response.data.numeroNuitDebEff1;
      var numeroNuitFinEff1=response.data.numeroNuitFinEff1;
      var numeroNuitDebEff2=response.data.numeroNuitDebEff2;
      var numeroNuitFinEff2=response.data.numeroNuitFinEff2;

      if(onDisplayElementChange)
      {
        var idJournee=0;
        var idNuit=0;

        if(result.libelleModif && numeroNuit > 0 && maxNuit > 0)
        {
          for(var i=numeroNuit; i<=maxNuit; i++)
          {
            idJournee=eventCategories[i-1].id_journee;
            idNuit=Number(eventCategories[i-1].evenements[eventCategories[i-1].evenements.length-1].id);

            onDisplayElementChange(idJournee, idNuit, 'nuit', editedDisplayName, 'libelle1');
            onDisplayElementChange(idJournee, idNuit, 'nuit', editedLatitude, 'lat');
            onDisplayElementChange(idJournee, idNuit, 'nuit', editedLongitude, 'longi');
            onDisplayElementChange(idJournee, idNuit, 'nuit', codePays, 'pays');
            if(codePays !== "")
            {
              onDisplayElementChange(idJournee, idNuit, 'nuit', listeNomPays.split("_")[listeIdPays.split("_").map(item => item.toLowerCase()).indexOf(codePays.toLowerCase())], 'nom_pays');
            }
            else
            {
              onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'nom_pays');
            }
          }
        }
        else if((result.paysSuppr || result.paysModif) && numeroNuit > 0 && maxNuit > 0)
        {
          for(var i=numeroNuit; i<=maxNuit; i++)
          {
            idJournee=eventCategories[i-1].id_journee;
            idNuit=Number(eventCategories[i-1].evenements[eventCategories[i-1].evenements.length-1].id);

            onDisplayElementChange(idJournee, idNuit, 'nuit', codePays, 'pays');
            if(codePays !== "")
            {
              onDisplayElementChange(idJournee, idNuit, 'nuit', listeNomPays.split("_")[listeIdPays.split("_").map(item => item.toLowerCase()).indexOf(codePays.toLowerCase())], 'nom_pays');
            }
            else
            {
              onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'nom_pays');
            }
          }
        }

         if(result.numNuitDebEff1 > 0)
					{
            if(numeroNuitDebEff1 > 0 && numeroNuitFinEff1 > 0)
            {

              for(var i=numeroNuitDebEff1; i<=numeroNuitFinEff1; i++)
              {
                idJournee=eventCategories[i-1].id_journee;
                idNuit=Number(eventCategories[i-1].evenements[eventCategories[i-1].evenements.length-1].id);

                onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'libelle1');
                onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'lat');
                onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'longi');
                onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'pays');
                onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'nom_pays');
              }
            }

            if(result.numNuitDebEff2 > 0)
						{
              if(numeroNuitDebEff2 > 0 && numeroNuitFinEff2 > 0)
              {
                for(var i=numeroNuitDebEff2; i<=numeroNuitFinEff2; i++)
                {
                  idJournee=eventCategories[i-1].id_journee;
                  idNuit=Number(eventCategories[i-1].evenements[eventCategories[i-1].evenements.length-1].id);

                  onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'libelle1');
                  onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'lat');
                  onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'longi');
                  onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'pays');
                  onDisplayElementChange(idJournee, idNuit, 'nuit', '', 'nom_pays');
                }
              }
            }

          }
        setIsLoading(false);
        handleCloseModalModifResume();
      }
      setIsLoading(false);
    })
    .catch((error: any) =>
    {
      setIsLoading(false);
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification des nuits, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification des nuits");
      }
    });
      
    
      }

  }

  const handleSelectDate = (valeur: number) => {

    setSelectedDate(valeur);
    var nouvNbNuits=selectedNbNuits;
    if(valeur+selectedNbNuits > nuits.length)
    {
      setSelectedNbNuits(nuits.length-valeur);
      nouvNbNuits=nuits.length-valeur;
    }

    var result=calculeIndicateurs(valeur, nouvNbNuits);

    if(editedDisplayName !== "")
    {

      var dateDebutPer1="";
      var dateFinPer1="";
      var dateDebutPer2="";
      var dateFinPer2="";
      var dateDebutPer3="";
      var dateFinPer3="";
      var periode1="";
      var periode2="";
      var periode3="";
      var libellePays="";
      var tableauInformations: string[]=[];

      if(result.dateReculee && result.nbNuitsDimin)
      {
        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateDebutPer1 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer1 = addDays(date, indice2);
          dateDebutPer2 = addDays(date, indice2+1);
        }

        var indice3=nuits.findIndex((item: any) => item.id === result.numNuitDebEff2);
        
        if (indice3 !== -1) {
          dateFinPer2 = addDays(date, indice3-1);
          dateDebutPer3 = addDays(date, indice3);
        }

        var indice4=nuits.findIndex((item: any) => item.id === result.numNuitFinEff2);
        
        if (indice4 !== -1) {
          dateFinPer3 = addDays(date, indice4);
        }

        if(selectRef.current?.value !== "-1")
        {
          libellePays=" - "+listeNomPays.split("_")[Number(selectRef.current?.value)];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+editedDisplayName+libellePays;
        }
        else
        {
          periode2=dateDebutPer2+" : "+editedDisplayName+libellePays;
        }

        if(dateDebutPer3 !== dateFinPer3)
        {
          periode3=t('du')+" "+dateDebutPer3+" "+t('au')+" "+dateFinPer3+" : "+(indice4-indice3+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        { 
          periode3=dateDebutPer3+" : 1 "+t('nuit')+" "+t('liberee');
        }
        
        tableauInformations.push(periode1);
        tableauInformations.push(periode2);
        tableauInformations.push(periode3);

        setTableauInfo(tableauInformations);

      }
      else if(result.dateReculee && !result.nbNuitsDimin)
      {
        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateDebutPer1 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer1 = addDays(date, indice2);
          dateDebutPer2 = addDays(date, indice2+1);
          dateFinPer2 = addDays(date, nuits.length-1);
        }

        if(selectRef.current?.value !== "-1")
        {
          libellePays=" - "+listeNomPays.split("_")[Number(selectRef.current?.value)];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+editedDisplayName+libellePays;
        }
        else
        {
          periode2=dateDebutPer2+" : "+editedDisplayName+libellePays;
        }
        
        tableauInformations.push(periode1);
        tableauInformations.push(periode2);

        setTableauInfo(tableauInformations);
        
      }
      else if(!result.dateReculee && result.nbNuitsDimin)
      {
        dateDebutPer1 = date;

        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateFinPer1 = addDays(date, indice1-1);
          dateDebutPer2 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer2 = addDays(date, indice2);
        }

        if(selectRef.current?.value !== "-1")
        {
          libellePays=" - "+listeNomPays.split("_")[Number(selectRef.current?.value)];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode2=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+editedDisplayName+libellePays;
        }
        else
        {
          periode2=dateDebutPer1+" : "+editedDisplayName+libellePays;
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode3=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode3=dateDebutPer2+" : 1 "+t('nuit')+" "+t('liberee');
        }

        
        tableauInformations.push(periode2);
        tableauInformations.push(periode3);

        setTableauInfo(tableauInformations);
      }
      else
      {
        setTableauInfo([]);
      }
    }

};

const handleSelectNbNuits = (valeur: number) => {


  setSelectedNbNuits(valeur);

  var result=calculeIndicateurs(Number(selectDateRef.current?.value), valeur);

  if(editedDisplayName !== "")
  {

    var dateDebutPer1="";
    var dateFinPer1="";
    var dateDebutPer2="";
    var dateFinPer2="";
    var dateDebutPer3="";
    var dateFinPer3="";
    var periode1="";
    var periode2="";
    var periode3="";
    var libellePays="";
    var tableauInformations: string[]=[];

    if(result.dateReculee && result.nbNuitsDimin)
      {
        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateDebutPer1 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer1 = addDays(date, indice2);
          dateDebutPer2 = addDays(date, indice2+1);
        }

        var indice3=nuits.findIndex((item: any) => item.id === result.numNuitDebEff2);
        
        if (indice3 !== -1) {
          dateFinPer2 = addDays(date, indice3-1);
          dateDebutPer3 = addDays(date, indice3);
        }

        var indice4=nuits.findIndex((item: any) => item.id === result.numNuitFinEff2);
        
        if (indice4 !== -1) {
          dateFinPer3 = addDays(date, indice4);
        }

        if(selectRef.current?.value !== "-1")
        {
          libellePays=" - "+listeNomPays.split("_")[Number(selectRef.current?.value)];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+editedDisplayName+libellePays;
        }
        else
        {
          periode2=dateDebutPer2+" : "+editedDisplayName+libellePays;
        }

        if(dateDebutPer3 !== dateFinPer3)
        {
          periode3=t('du')+" "+dateDebutPer3+" "+t('au')+" "+dateFinPer3+" : "+(indice4-indice3+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        { 
          periode3=dateDebutPer3+" : 1 "+t('nuit')+" "+t('liberee');
        }
        
        tableauInformations.push(periode1);
        tableauInformations.push(periode2);
        tableauInformations.push(periode3);

        setTableauInfo(tableauInformations);

      }
      else if(result.dateReculee && !result.nbNuitsDimin)
      {
        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateDebutPer1 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer1 = addDays(date, indice2);
          dateDebutPer2 = addDays(date, indice2+1);
          dateFinPer2 = addDays(date, nuits.length-1);
        }

        if(selectRef.current?.value !== "-1")
        {
          libellePays=" - "+listeNomPays.split("_")[Number(selectRef.current?.value)];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+editedDisplayName+libellePays;
        }
        else
        {
          periode2=dateDebutPer2+" : "+editedDisplayName+libellePays;
        }
        
        tableauInformations.push(periode1);
        tableauInformations.push(periode2);

        setTableauInfo(tableauInformations);
        
      }
      else if(!result.dateReculee && result.nbNuitsDimin)
      {
        dateDebutPer1 = date;

        var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
        
        if (indice1 !== -1) {
          dateFinPer1 = addDays(date, indice1-1);
          dateDebutPer2 = addDays(date, indice1);
        }

        var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
        
        if (indice2 !== -1) {
          dateFinPer2 = addDays(date, indice2);
        }

        if(selectRef.current?.value !== "-1")
        {
          libellePays=" - "+listeNomPays.split("_")[Number(selectRef.current?.value)];
        }
        else
        {
          libellePays=" - "+t('paysNonRenseigne');
        }

        tableauInformations.push(t('situationApresEnregistrement'));

        if(dateDebutPer1 !== dateFinPer1)
        {
          periode2=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+editedDisplayName+libellePays;
        }
        else
        {
          periode2=dateDebutPer1+" : "+editedDisplayName+libellePays;
        }

        if(dateDebutPer2 !== dateFinPer2)
        {
          periode3=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
        }
        else
        {
          periode3=dateDebutPer2+" : 1 "+t('nuit')+" "+t('liberee');
        }

        
        tableauInformations.push(periode2);
        tableauInformations.push(periode3);

        setTableauInfo(tableauInformations);
      }
      else
      {
        setTableauInfo([]);
      }
    }
};

const handleSelectPays = (valeur: number) => {

  var nouvIdCodePays=valeur;
  if(valeur === -1)
  {
    setCodePays("");
    setTexteInformatif(t('paysNonRenseigneCliquez'));
  }
  else
  {
    setCodePays(listeIdPays.split("_")[valeur]);
    setTexteInformatif("");
  }

  var result=calculeIndicateurs(selectedDate, selectedNbNuits);

              if(editedDisplayName !== "")
              {

                var dateDebutPer1="";
                var dateFinPer1="";
                var dateDebutPer2="";
                var dateFinPer2="";
                var dateDebutPer3="";
                var dateFinPer3="";
                var periode1="";
                var periode2="";
                var periode3="";
                var libellePays="";
                var tableauInformations: string[]=[];

                if(result.dateReculee && result.nbNuitsDimin)
                {
                  var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
                  
                  if (indice1 !== -1) {
                    dateDebutPer1 = addDays(date, indice1);
                  }

                  var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
                  
                  if (indice2 !== -1) {
                    dateFinPer1 = addDays(date, indice2);
                    dateDebutPer2 = addDays(date, indice2+1);
                  }

                  var indice3=nuits.findIndex((item: any) => item.id === result.numNuitDebEff2);
                  
                  if (indice3 !== -1) {
                    dateFinPer2 = addDays(date, indice3-1);
                    dateDebutPer3 = addDays(date, indice3);
                  }

                  var indice4=nuits.findIndex((item: any) => item.id === result.numNuitFinEff2);
                  
                  if (indice4 !== -1) {
                    dateFinPer3 = addDays(date, indice4);
                  }

                  if(nouvIdCodePays !== -1)
                  {
                    libellePays=" - "+listeNomPays.split("_")[nouvIdCodePays];
                  }
                  else
                  {
                    libellePays=" - "+t('paysNonRenseigne');
                  }

                  tableauInformations.push(t('situationApresEnregistrement'));

                  if(dateDebutPer1 !== dateFinPer1)
                  {
                    periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  {
                    periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
                  }

                  if(dateDebutPer2 !== dateFinPer2)
                  {
                    periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+editedDisplayName+libellePays;
                  }
                  else
                  {
                    periode2=dateDebutPer2+" : "+editedDisplayName+libellePays;
                  }

                  if(dateDebutPer3 !== dateFinPer3)
                  {
                    periode3=t('du')+" "+dateDebutPer3+" "+t('au')+" "+dateFinPer3+" : "+(indice4-indice3+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  { 
                    periode3=dateDebutPer3+" : 1 "+t('nuit')+" "+t('liberee');
                  }
                  
                  tableauInformations.push(periode1);
                  tableauInformations.push(periode2);
                  tableauInformations.push(periode3);

                  setTableauInfo(tableauInformations);

                }
                else if(result.dateReculee && !result.nbNuitsDimin)
                {
                  var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
                  
                  if (indice1 !== -1) {
                    dateDebutPer1 = addDays(date, indice1);
                  }

                  var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
                  
                  if (indice2 !== -1) {
                    dateFinPer1 = addDays(date, indice2);
                    dateDebutPer2 = addDays(date, indice2+1);
                    dateFinPer2 = addDays(date, nuits.length-1);
                  }

                  if(nouvIdCodePays !== -1)
                  {
                    libellePays=" - "+listeNomPays.split("_")[nouvIdCodePays];
                  }
                  else
                  {
                    libellePays=" - "+t('paysNonRenseigne');
                  }

                  tableauInformations.push(t('situationApresEnregistrement'));

                  if(dateDebutPer1 !== dateFinPer1)
                  {
                    periode1=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  {
                    periode1=dateFinPer1+" : 1 "+t('nuit')+" "+t('liberee');
                  }

                  if(dateDebutPer2 !== dateFinPer2)
                  {
                    periode2=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+editedDisplayName+libellePays;
                  }
                  else
                  {
                    periode2=dateDebutPer2+" : "+editedDisplayName+libellePays;
                  }
                  
                  tableauInformations.push(periode1);
                  tableauInformations.push(periode2);

                  setTableauInfo(tableauInformations);
                  
                }
                else if(!result.dateReculee && result.nbNuitsDimin)
                {
                  dateDebutPer1 = date;

                  var indice1=nuits.findIndex((item: any) => item.id === result.numNuitDebEff1);
                  
                  if (indice1 !== -1) {
                    dateFinPer1 = addDays(date, indice1-1);
                    dateDebutPer2 = addDays(date, indice1);
                  }

                  var indice2=nuits.findIndex((item: any) => item.id === result.numNuitFinEff1);
                  
                  if (indice2 !== -1) {
                    dateFinPer2 = addDays(date, indice2);
                  }

                  if(nouvIdCodePays !== -1)
                  {
                    libellePays=" - "+listeNomPays.split("_")[nouvIdCodePays];
                  }
                  else
                  {
                    libellePays=" - "+t('paysNonRenseigne');
                  }

                  tableauInformations.push(t('situationApresEnregistrement'));

                  if(dateDebutPer1 !== dateFinPer1)
                  {
                    periode2=t('du')+" "+dateDebutPer1+" "+t('au')+" "+dateFinPer1+" : "+editedDisplayName+libellePays;
                  }
                  else
                  {
                    periode2=dateDebutPer1+" : "+editedDisplayName+libellePays;
                  }

                  if(dateDebutPer2 !== dateFinPer2)
                  {
                    periode3=t('du')+" "+dateDebutPer2+" "+t('au')+" "+dateFinPer2+" : "+(indice2-indice1+1)+" "+t('nuit')+"s "+t('liberee')+"s";
                  }
                  else
                  {
                    periode3=dateDebutPer2+" : 1 "+t('nuit')+" "+t('liberee');
                  }

                  
                  tableauInformations.push(periode2);
                  tableauInformations.push(periode3);

                  setTableauInfo(tableauInformations);
                }
                else
                {
                  setTableauInfo([]);
                }
              }

};


const handleInputFocus = () => {
  setOldDisplayName(editedDisplayName);
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  if (editedDisplayName !== "" && editedDisplayName.length >2 ) {
    const id = setTimeout(() => {
      fetchSuggestions(editedDisplayName, '');
    }, 600);

    setTimeoutId(id);
  }
};


  const modalRef = useRef(null);

  const handleCloseModalModifResume = () => {
    onModifResumeCloseModal();
  };
    return (
      <>
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalModifResume}
        ref={modalRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Modification 
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={handleCloseModalModifResume} />
                    </span>
                  </div>
                  <div className="px-8 text-lg overflow-auto text-neutral-700 dark:text-neutral-300">
                      <div className="mb-4 pt-5 w-full text-sm text-center grid grid-cols-2">
                      
                      <div>{t('dateDebut')}</div>
                      <div>
                        <SelectPaiementHebergement
                        value={selectedDate}
                        onChange={(event) => handleSelectDate(Number(event.target.value))}
                        className="pointeur"
                        ref={selectDateRef}
                        >
                        { nuits.map((nuit, index: number) => (
                              <option key={`date-${index}`}  value={index}>{addDays(date,index)}</option>
                            ))}
                        </SelectPaiementHebergement>
                      </div>
                      <div className="mt-2">{t('nombreNuits')}</div>
                      <div className="mt-2">
                        <SelectPaiementHebergement
                        value={selectedNbNuits}
                        onChange={(event) => handleSelectNbNuits(Number(event.target.value))}
                        className="pointeur"
                        ref={selectNbNuitsRef}
                      >
                           { nuits.map((nuit, index:number) => (
                             index >= selectedDate && (
                            <option key={`nb-nuits-${index}`}  value={index-selectedDate+1}>{index-selectedDate+1}</option>
                             )
                          ))}
                        </SelectPaiementHebergement>
                      </div>
                      </div>
                      <div className="mb-4 w-full text-left dark:prose-dark">
                      

                      <div className={`text-base font-medium w-full mt-5`} ref={divAutocompleteRef}>
                      <input
                        type="text"
                        value={oldDisplayName}
                        ref={oldInputRef}
                        className="hidden"
                        readOnly
                      />
                      <input
                        type="text"
                        value={editedDisplayName}
                        onChange={handleDisplayNameChange}
                        className="champAutocomplete"
                        placeholder={t('villeSejourner')}
                        maxLength={50}
                        onFocus={handleInputFocus}
                        ref={inputRef}
                      />
                      {/* Afficher la liste de suggestions en tant que pop-up */}
                      {isModalOpen && suggestions.length > 0 && (
                        <AutoCompleteList
                          suggestions={suggestions}
                          onSelect={handleSelectSuggestion}
                          classe="autocomplete-list-resume"
                        />
                      )}
                  </div>
                  <div className="text-center text-xs mt-3 mb-3">
                    <p className="couleurRose">{texteInformatif}</p>
                  </div>
                  <div>
                      <SelectPaiementHebergement
                        value={listeIdPays.split("_").map(item => item.toLowerCase()).indexOf(codePays.toLowerCase())}
                        onChange={(event) => handleSelectPays(Number(event.target.value))}
                        className="pointeur mt-2"
                        ref={selectRef}
                      >
                          <option value="-1">{t('pays')}</option>
                          { listeNomPays.split("_").map((p, index: number) => (
                            <option key={`pays-${index}`} value={index}>{p}</option>
                          ))}
                      </SelectPaiementHebergement>
                      </div>
                        { tableauInfo.length > 0 && (
                         <div className="text-left text-xs mt-5 mb-3 ml-5 prose dark:prose-invert dark:prose-dark texteItalique">
                           <p>{tableauInfo[0]}</p>
                           <ul>
                             {tableauInfo.map((info, index) => (
                               index > 0 && (
                                <li key={index}>{info}</li>
                               )
                             ))}
                           </ul>
                          </div>
                        )}
                      <div className="flex justify-center mt-12 mb-8 "> 
                        <ButtonPrimary onClick={handleModifierNuits}>
                          {t('enregistrer')}
                        </ButtonPrimary>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>


{isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('enregistrement')}
            </h3>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}
</>
  );
};

export default ModalModifResume;
