import React, { FC, useEffect, useState, useRef, useCallback, Fragment } from "react";
import { TaxonomyType, FeatureGeoapifyType } from "data/types";
import CardNuitResume from "components/CardNuitResume/CardNuitResume";
import ItemsSlider from 'components/ItemsSlider';
import ConvertisseurMonnaie from 'components/ConvertisseurMonnaie';
import ModalBooking from 'components/ModalBooking';
import ModalStatistiques from 'components/ModalStatistiques';
import ModalLieuxResume from 'components/ModalLieuxResume';
import ModalModifResume from 'components/ModalModifResume';
import ModalContenuBagage from 'components/ModalContenuBagage';
import ModalImport from 'components/ModalImport';
import ModalExport from 'components/ModalExport';
import ModalIntegration from 'components/ModalIntegration';
import ModalPartageRS from 'components/ModalPartageRS';
import BtnLikeVoyageIcon from "components/BtnLikeVoyageIcon/BtnLikeVoyageIcon";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BtnOutils from "components/BtnOutils";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Textarea from "shared/Textarea/Textarea";
import { useDrag, useDrop, DndProvider, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { voyageService } from '_services/voyage.service';
import { useNavigate } from "react-router-dom";
import { JourneeType, EventType, PecType } from "data/types";
import { Dialog, Transition } from "@headlessui/react";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface GroupVille {
  libelle1: string;
  lat: string | null;
  longi: string | null;
  firstDay: string;
  firstDayId: number;
  image?: string;
  nights: EventType[];
}

interface GroupNuits {
  pays: string;
  nom_pays: string;
  groupVilles: GroupVille[];
}

interface Flags {
  [key: string]: string | undefined;
}

export interface PlanificateurResumeProps {
  className?: string;
  itemClassName?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  isModif: boolean;
  onChangeModif: () => void;
  mailKo: boolean;
  eventCategoriesPage: JourneeType[];
  id_pays: string;
  libelles_pays: string;
  nbJours: number;
  visibilite: string;
  etatVoyage: number;
  nb_voyageurs: number;
  monnaie: string;
  numDroit: number; 
  setEventCategoriesPage: (updatedCategories :any) => void;
  username: string;
  affichageBudget: string;
  nb_likes: number;
  liked: string;
  onMajLike: () => void;
  onMajDislike: () => void;
  verrouillageModif: () => Promise<number>;
}

interface DraggableCardProps {
  index: number; // Use index instead of id
  id_journee:number;
  onDrop: (dragIndex: number, hoverIndex: number, draggedIdJournee:number) => void;
  children?: React.ReactNode;
  eventCategories: JourneeType[];
  groupedNights: any[];
}

const DraggableCard: FC<DraggableCardProps> = ({ index, id_journee, onDrop, children, eventCategories, groupedNights }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { index, id_journee },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  
  const ref = useRef<HTMLDivElement>(null);


  const [, drop] = useDrop({
    accept: 'CARD',
    drop: (item: any, monitor: DropTargetMonitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const draggedIdJournee = item.id_journee;

        onDrop(dragIndex, hoverIndex, draggedIdJournee);
    
    },
  });

  useEffect(() => {
    drag(drop(ref));
  }, []); // Effectuera cette opération une seule fois au montage initial

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </div>
  );
};


const PlanificateurResume: FC<PlanificateurResumeProps> = ({
  categoryCardType = "card3",
  voyageKey,
  emailPartage,
  codePartage,
  isModif,
  onChangeModif,
  mailKo,
  eventCategoriesPage,
  id_pays,
  libelles_pays,
  nbJours,
  visibilite,
  etatVoyage,
  nb_voyageurs,
  monnaie,
  numDroit,
  setEventCategoriesPage,
  username,
  affichageBudget,
  nb_likes,
  liked,
  onMajLike,
  onMajDislike,
  verrouillageModif
}) => {
  const { t } = useTranslation();

  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [selectedEvent, setSelectedEvent] = useState<EventType | null>(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(0);
  const [selectedJournee, setSelectedJournee] = useState<number | null>(null);
  const [listePec, setListePec] = useState<PecType[]>([]);


  const [lieu1, setLieu1] = useState("");
  const [lieu2, setLieu2] = useState("");
  const [latitude1, setLatitude1] = useState("");
  const [latitude2, setLatitude2] = useState("");
  const [longitude1, setLongitude1] = useState("");
  const [longitude2, setLongitude2] = useState("");
  
  const [isModalConvertisseurOpen, setIsModalConvertisseurOpen] = useState(false);
  const [isModalStatistiquesOpen, setIsModalStatistiquesOpen] = useState(false);
  const [isModalContenuBagageOpen, setIsModalContenuBagageOpen] = useState(false);
  const [isModalImportOpen, setIsModalImportOpen] = useState(false);
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const [isModalLieuxResumeOpen, setIsModalLieuxResumeOpen] = useState(false);
  const [isModalModifResumeOpen, setIsModalModifResumeOpen] = useState(false);
  const [isModalIntegrationOpen, setIsModalIntegrationOpen] = useState(false);
  const [isModalPartageOpen, setIsModalPartageOpen] = useState(false);

  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false);

  const [idEvenementForModal, setIdEvenementForModal] = useState<number>(0);
  const [typeEvenementForModal, setTypeEvenementForModal] = useState("");

  const [statNbEtapes, setStatNbEtapes] = useState("");
  const [statBudget, setStatBudget] = useState("");
  const [statDistance, setStatDistance] = useState("");
  const [statDistanceAvion, setStatDistanceAvion] = useState("");
  const [statDistanceBateau, setStatDistanceBateau] = useState("");
  const [statDistanceTrain, setStatDistanceTrain] = useState("");
  const [statDistanceBus, setStatDistanceBus] = useState("");
  const [statDistanceTaxi, setStatDistanceTaxi] = useState("");
  const [statDistanceVoiture, setStatDistanceVoiture] = useState("");
  const [statDistanceMoto, setStatDistanceMoto] = useState("");
  const [statDistanceVelo, setStatDistanceVelo] = useState("");
  const [statDistancePied, setStatDistancePied] = useState("");

  const [groupedNights, setGroupedNights] = useState<any[]>([]);
  const [flags, setFlags] = useState<Flags>({});
  const [lieuxVisites, setLieuxVisites] = useState<Array<string>>([]);
  const [nuitsModif, setNuitsModif] = useState<Array<EventType>>([]);
  const [paysModif, setPaysModif] = useState<string>("");
  const [firstDayModif, setFirstDayModif] = useState<string>("");
  const [firstDayIdModif, setFirstDayIdModif] = useState<number>(0);

  const [nbLikes, setNbLikes] = useState<number>(nb_likes || 0);

  const [isAdding, setIsAdding] = useState(false);

  // Fonction pour obtenir la valeur d'un cookie
  const getCookie = (name: string) => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(name))
      ?.split('=')[1];
    return cookieValue || '';
  };


const [isLiked, setIsLiked] = useState(() => {

if(liked === 'O')
{
  return true;
}
else
{
  // Vérifie si le cookie existe
  const cookieName = `tripkygoLike${voyageKey}`;
  const cookieValue = getCookie(cookieName);

  // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
  if (cookieValue === 'O') {
    return true;
  }
}
return false;
});

     
   // Fonction de gestion du clic sur le bouton like
   const handleLikeClick = useCallback(async () => {
    var etatLike=isLiked;
  
    setNbLikes((prevNbLikes) => (etatLike ? prevNbLikes - 1 : prevNbLikes + 1));
  
    // Mise à jour de l'état local
    setIsLiked((prevIsLiked) => !prevIsLiked);
  
    if(!etatLike)
    {
      // Ajout du cookie
      const cookieName = `tripkygoLike${voyageKey}`;
      document.cookie = `${cookieName}=O; path=/`;
  
      const xsrfToken = localStorage.getItem('xsrfToken');
  
      onMajLike(); 

        try{
          const response = await voyageService.likeVoyage({ id_voyage: voyageKey }, {
            headers: {
              'X-XSRF-Token': xsrfToken,
            },
          })
          
        } catch (error: any) {
          console.error("Error like voyage :", error);
        } finally {
        
        }
  
    }
    else
    {
      // Suppression du cookie
    const cookieName = `tripkygoLike${voyageKey}`;
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  
    const xsrfToken = localStorage.getItem('xsrfToken');

    onMajDislike();

      try{
        const response = await voyageService.dislikeVoyage(voyageKey, {
          headers: {
            'X-XSRF-Token': xsrfToken,
          },
        })
        
      } catch (error: any) {
        console.error("Error dislike voyage:", error);
      } finally {
      
      }
  
    }
  
    
  }, [isLiked, voyageKey]);
  
  
  const toggleModif = () => {
    onChangeModif();
  };
  
  type OnAddEvenementType = (idJournee: number, idEvenement: number, libelleLieu: string, description: string, latitude: string, longitude: string) => number;


  let navigate=useNavigate();

  const handleElementChange = useCallback(
    (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement: string) => {
      setEventCategories((prevCategories) => {
        return prevCategories.map((day) => {
          if (day.id_journee === journeeId) {
            return {
              ...day,
              evenements: day.evenements.map((event) => {
                if (
                  (typeEvenement === 'lieu' && event.typeEv === 'L' && event.id === eventId) ||
                  (typeEvenement === 'trajet' && event.typeEv === 'T' && event.id === eventId) ||
                  (typeEvenement === 'nuit' && event.typeEv === 'N' && event.id === eventId)
                ) {
                  if (typeElement === 'libelle1') {
                    return { ...event, libelle1: valeur };
                  } else if (typeElement === 'libelle2') {
                    return { ...event, libelle2: valeur };
                  } else if (typeElement === 'prix') {
                    return { ...event, prix: valeur };
                  } else if (typeElement === 'paiement') {
                    return { ...event, paiement: valeur };
                  } else if (typeElement === 'acompte_hebergement') {
                    return { ...event, acompte_hebergement: valeur };
                  } else if (typeElement === 'date_paiement_hebergement') {
                    return { ...event, date_paiement_hebergement: valeur };
                  } else if (typeElement === 'debut') {
                    return { ...event, debut: valeur };
                  } else if (typeElement === 'fin') {
                    return { ...event, fin: valeur };
                  } else if (typeElement === 'compagnie') {
                    return { ...event, compagnie: valeur };
                  } else if (typeElement === 'ref') {
                    return { ...event, ref: valeur };
                  } else if (typeElement === 'distance') {
                    return { ...event, distance: valeur };
                  } else if (typeElement === 'duree') {
                    return { ...event, duree: valeur };
                  } else if (typeElement === 'invisible1') {
                    return { ...event, invisible1: valeur };
                  } else if (typeElement === 'invisible2') {
                    return { ...event, invisible2: valeur };
                  } else if (typeElement === 'type') {
                    return { ...event, type: valeur };
                  } else if (typeElement === 'moment') {
                    return { ...event, moment: valeur };
                  } else if (typeElement === 'rattachement') {
                    return { ...event, rattachement: valeur };
                  } else if (typeElement === 'image') {
                    return { ...event, image: valeur };
                  } else if (typeElement === 'lat') {
                    return { ...event, lat: valeur };
                  } else if (typeElement === 'longi') {
                    return { ...event, longi: valeur };
                  } else if (typeElement === 'lat2') {
                    return { ...event, lat2: valeur };
                  } else if (typeElement === 'longi2') {
                    return { ...event, longi2: valeur };
                  } else if (typeElement === 'place') {
                    return { ...event, place: valeur };
                  } else if (typeElement === 'latModif') {
                    return { ...event, latModif: valeur };
                  } else if (typeElement === 'longiModif') {
                    return { ...event, longiModif: valeur };
                  } else if (typeElement === 'lat2Modif') {
                    return { ...event, lat2Modif: valeur };
                  } else if (typeElement === 'longi2Modif') {
                    return { ...event, longi2Modif: valeur };
                  } else if (typeElement === 'document') {
                    return { ...event, document: valeur };
                  } else if (typeElement === 'lien') {
                    return { ...event, lien: valeur };
                  } else if (typeElement === 'bloc') {
                    return { ...event, bloc: valeur };
                  } else if (typeElement === 'pays') {
                    return { ...event, pays: valeur };
                  } else if (typeElement === 'nom_pays') {
                    return { ...event, nom_pays: valeur };
                  }
                }
                return event;
              }),
            };
          }
          return day;
        });
      });
    },
    [] // dependencies, update if needed
  );

  useEffect(() => {
    
    if (JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage)) {
      setEventCategories(eventCategoriesPage);
      setLoading(false); 
    }
  }, [eventCategoriesPage]);

  useEffect(() => {
    if (!loading) {
      // Mettre à jour eventCategoriesPage lorsque eventCategories change
      setEventCategoriesPage(eventCategories);
    }
  }, [eventCategories]);

  useEffect(() => {
    const groups: GroupNuits[] = [];
    let currentGroupPays: GroupNuits | null = null;
    let currentGroupVille: GroupVille | null = null;

    eventCategories.forEach((day) => {
      day.evenements.forEach((evenement) => {

        if ((isModif 
          || (!isModif 
            && (evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport" || (evenement.nom_pays !== null && evenement.nom_pays !== '')))) 
          && evenement.typeEv === 'N') {

          const paysNom = evenement.nom_pays || ( 
            !currentGroupPays && (evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport") ? t('trajet') 
            : currentGroupPays && (evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport") && currentGroupPays.nom_pays !== t('nuitsdIncompletes') ? currentGroupPays.nom_pays 
            : currentGroupPays && (evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport") && currentGroupPays.nom_pays === t('nuitsdIncompletes')  ? t('trajet') 
            :  t('nuitsIncompletes')
          );

          const codePays = evenement.pays || "";

          if (!currentGroupPays || currentGroupPays.nom_pays !== paysNom) {
            currentGroupPays = {
              nom_pays: paysNom,
              pays: codePays,
              groupVilles: []
            };
            groups.push(currentGroupPays);
            currentGroupVille = null; // Reset the current city group
          }

          const villeNom = evenement.libelle1 || t('cliquezCompleter');

          if (!currentGroupVille || currentGroupVille.libelle1 !== villeNom) {
            currentGroupVille = {
              libelle1: villeNom,
              lat: evenement.lat,
              longi: evenement.longi,
              firstDay: day.date_journee.split(' ')[1],
              firstDayId: day.id_journee,
              image: evenement.image || '',
              nights: [evenement]
            };
            currentGroupPays.groupVilles.push(currentGroupVille);
          } else {
            currentGroupVille.nights.push(evenement);
          }

          if (!currentGroupVille.image && evenement.image) {
            currentGroupVille.image = evenement.image;
          }
        }
      });
    });

    setGroupedNights(groups);
  }, [eventCategories, isModif]);
  
  useEffect(() => {
    groupedNights.forEach(group => {
      if (group.pays && !flags[group.pays]) {
        apiRestcountries(group.pays)
          .then(data => {
            setFlags(prevFlags => ({
              ...prevFlags,
              [group.pays]: data[0]?.flag || ''
            }));
          })
          .catch(error => {
            console.error(`Error fetching flag for ${group.pays}:`, error);
            setFlags(prevFlags => ({
              ...prevFlags,
              [group.pays]: ''
            }));
          });
      }
    });
  }, [groupedNights, flags]);
    
  useEffect(() => {
    const updatedListePec = eventCategories.flatMap((day) =>
    day.evenements
      .filter(
        (event) =>
          event.location === 'O' &&
          event.moment === 'P'
      )
      .map((event) => ({
        date_journee: day.date_journee,
        id_journee: day.id_journee,
        id_evenement: event.id, 
        type_transport: event.type || "",
        rattachement: event.rattachement || "",
        debut: event.debut || ""
      }))
  );

  setListePec(updatedListePec);

  }, [eventCategories]);

  function apiRestcountries(codePays: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `https://restcountries.com/v3.1/alpha/${codePays}`;
      fetch(url)
        .then(response => {
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(error => reject(error));
          }
        })
        .catch(error => reject(error));
    });
  }
  

  if (loading) {
    return <div className="text-center mt-20 mb-20"><h1 className="text-xl">{t('chargementVoyage')}</h1></div>;
  }


  const closeModal = () => {
    setSelectedEvent(null);
    setSelectedEventIndex(0);
    setSelectedJournee(null);
    setIsModalOpen(false);
  };

  const openModalConvertisseur = () => {
    setIsModalConvertisseurOpen(true);
  };

  const closeModalConvertisseur = () => {
    setIsModalConvertisseurOpen(false);
  };

  
  const openModalBooking = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalBookingOpen(true);
  };

  const closeModalBooking = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalBookingOpen(false);
  };

  const openModalLieuxResume = () => {
    setIsModalLieuxResumeOpen(true);
  };

  const closeModalLieuxResume = () => {
    setIsModalLieuxResumeOpen(false);
  };

  const openModalModifResume = () => {
    setIsModalModifResumeOpen(true);
  };

  const closeModalModifResume = () => {
    setIsModalModifResumeOpen(false);
  };
  
  const openModalStatistiques = () => {
    setIsModalStatistiquesOpen(true);
  };

  const closeModalStatistiques = () => {
    setIsModalStatistiquesOpen(false);
  };
  
  const openModalContenuBagage = () => {
    setIsModalContenuBagageOpen(true);
  };

  const closeModalContenuBagage = () => {
    setIsModalContenuBagageOpen(false);
  };

  const closeModalImport = () => {
    setIsModalImportOpen(false);
  };

  const openModalImport = () => {
    setIsModalImportOpen(true);
  };

  const closeModalIntegration = () => {
    setIsModalIntegrationOpen(false);
  };

  const openModalIntegration = () => {
    setIsModalIntegrationOpen(true);
  };

  const closeModalPartage = () => {
    setIsModalPartageOpen(false);
  };

  const openModalPartage = () => {
    setIsModalPartageOpen(true);
  };


  const closeModalExport = () => {
    setIsModalExportOpen(false);
  };

  const openModalExport = () => {
    setIsModalExportOpen(true);
  };

  const handleAddEvenement = (idJournee: number, idEvenement: number, libelleLieu: string, description: string, latitude: string, longitude: string, placeId: string): number  => {
  
    const modifVoyage = async () => {
      try {

      setIsAdding(true);

      const day = eventCategories.find((day) => day.id_journee === idJournee);
      let foundEvent: EventType | null = null;
      let eventIndex: number = -1;
    
      if (day) {
        const index = day.evenements.findIndex((event: EventType) => event.id === idEvenement);
    
        if (index !== -1) {
          eventIndex=index;
          foundEvent = day.evenements[index];
        } 
      }

      const type='lieu';
  
        if (foundEvent !== null && eventIndex !== null && eventIndex !== -1) {
          
          // Effectuez la requête API pour ajouter un événement
            voyageService.ajouterEvenement(voyageKey, {
                type,
                journee: idJournee,
                idEvenement: foundEvent.id,
                typeEvenement: foundEvent.typeEv
            })
          .then((response: any) => {
    
            const apiResult = response.data;
    
      
            // Vérifiez si la réponse de l'API est "Erreur"
            if (apiResult !== "Erreur") {
    
              // Fermez la modale
              closeModal();
      
              setEventCategories((prevCategories) => {
                // Trouvez la journée correspondante dans la liste
                const updatedCategories = prevCategories.map((day) => {
                  if (day.id_journee === idJournee) {
                    
                    // Créez une copie du tableau des événements
                    const updatedEvenements = [...day.evenements];

                    // Ajoutez la CardLieuModifie au-dessus du bon événement
                    updatedEvenements.splice(eventIndex, 0, {
                      typeEv: 'L',
                      id: apiResult.message, // Utilisez le résultat de l'API comme ID de l'événement
                      ordre: foundEvent?.ordre || 0,
                  libelle1: "",
                  libelle2: "",
                  prix: "",
                  paiement: "",
                  lat: "",
                  longi: "",
                  place: "",
                  bloc: "",
                  type: '',
                  lien: "",
                  etat_lien: "",
                  lien_modifie: "",
                  ref: "",
                  compagnie: "",
                  debut: "",
                  fin: "",
                  lat2: "",
                  longi2: "",
                  distance: "",
                  duree: "",
                  location: '',
                  moment: '',
                  rattachement: "",
                  invisible1: "",
                  invisible2: "",
                  date_paiement_hebergement: "",
                  acompte_hebergement: "",
                  image: "",
                  document: ""
                });

                // Retourner une copie de l'objet avec les événements mis à jour
                return {
                  ...day,
                  evenements: updatedEvenements,
                };
    
                         }
                        return day;
                      });
            
                      return updatedCategories;
                    });
    
                  } else {
                    setIsAdding(false);
                    console.error("Erreur lors de la requête API");
                    return -1
                    // Gérez l'erreur, affichez un message, etc.
                  }
    
                  const idNouvLieu=apiResult.message ? parseInt(apiResult.message, 10) : 0;

            
                  if (idNouvLieu !== 0) {

                    // Utilisez await pour attendre que modifierLieu soit terminé
                    modifierLieu(idJournee, idNouvLieu, libelleLieu, description, latitude, longitude, placeId);
                    setIsAdding(false);
                    return 0;
                  }
                  else
                  {
                    setIsAdding(false);
                  }
            })
          .catch((error: any) => {
            setIsAdding(false);
            if (error.response?.status === 401) {
              alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'ajout d'évenement");
              return -1;
            }
            console.error("Erreur lors de l'ajout d'évenement:", error);
          });
      
          } 
          else
          {
            setIsAdding(false);
          }
    } catch (error) {
      setIsAdding(false);
      console.error("Une erreur s'est produite :", error);
      alert("Une erreur s'est produite lors de la manipulation des événements.");
      return -1;
    }
  };

  const processModifVoyage = async () => {
    
    const verrouillageResultat = await verrouillageModif();

    if (verrouillageResultat === 1) {
      modifVoyage();
    }
  };

  processModifVoyage();
    return 0;
  };  

  const modifierLieu = (idJournee : number, idEvenementNouv : number , libelleLieu : string, description : string, latitude : string, longitude : string, placeId: string) => {

      handleElementChange(idJournee, idEvenementNouv, 'lieu', libelleLieu, 'libelle1');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', latitude, 'lat');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', longitude, 'longi');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', placeId, 'place');

      if(description !== null && description !== "")
      {
        handleElementChange(idJournee, idEvenementNouv, 'lieu', description, 'libelle2');
      }
         
      //enregistrement de la modification
      voyageService.modifierEvenement(voyageKey, {
        typeEvenement: 'lieu',
        idEvenement: idEvenementNouv,
        colonne: "libelle_lieu",
        contenu: libelleLieu,
        colonne1: 'lat_lieu',
        colonne2: 'long_lieu',
        contenu1: latitude,
        contenu2: longitude,
        placeId: placeId
      })
      .then((response: any) => {
        
        if(description !== null && description !== "")
        {
          //enregistrement de la modification
          voyageService.modifierEvenement(voyageKey, {
            typeEvenement: 'lieu',
            idEvenement: idEvenementNouv,
            colonne: "activites",
            contenu: description,
            conservation: false
          })
          .then((response: any) => {

            
          })
          .catch((error: any) =>
          {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'enregistrement de la modification de l'évenement");
            }
          });
        }
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
  };

  const addEvenement = async (type: string) => {

    const modifVoyage = async () => {
      try {
    setIsAdding(true);

    if (selectedEvent && selectedJournee) {
      
      // Effectuez la requête API pour ajouter un événement
        voyageService.ajouterEvenement(voyageKey, {
            type,
            journee: selectedJournee,
            idEvenement: selectedEvent.id,
            typeEvenement: selectedEvent.typeEv
        })
      .then((response: any) => {

        const apiResult = response.data;

        // Vérifiez si la réponse de l'API est "Erreur"
        if (apiResult !== "Erreur") {

          // Fermez la modale
          closeModal();


          setEventCategories((prevCategories) => {
            // Trouvez la journée correspondante dans la liste
            const updatedCategories = prevCategories.map((day) => {
              if (day.id_journee === selectedJournee) {

                // Créez une copie du tableau des événements
                const updatedEvenements = [...day.evenements];

                // Ajoutez la CardLieuModifie au-dessus du bon événement
                updatedEvenements.splice(selectedEventIndex, 0, {
                  typeEv: type === 'lieu' || type === 'repas' ? 'L' : type === 'trajet' || type === 'location' ? 'T' : '',
                  id: apiResult.message, // Utilisez le résultat de l'API comme ID de l'événement
                  ordre: selectedEvent.ordre,
              libelle1: "",
              libelle2: "",
              prix: "",
              paiement: "",
              lat: "",
              longi: "",
              place: "",
              bloc: "",
              type: type === 'repas' ? 'O' : '',
              lien: "",
              etat_lien: "",
              lien_modifie: "",
              ref: "",
              compagnie: "",
              debut: "",
              fin: "",
              lat2: "",
              longi2: "",
              distance: "",
              duree: "",
              location: type === 'location' ? 'O' : '',
              moment: type === 'location' ? 'P' : '',
              rattachement: "",
              invisible1: "",
              invisible2: "",
              date_paiement_hebergement: "",
              acompte_hebergement: "",
              image: "",
              document: ""
            });

                  // Retourner une copie de l'objet avec les événements mis à jour
                  return {
                    ...day,
                    evenements: updatedEvenements,
                  };

                    }
                    return day;
                  });
      
                  return updatedCategories;
                });
                setIsAdding(false);
              } else {
                setIsAdding(false);
                console.error("Erreur lors de la requête API");
                // Gérez l'erreur, affichez un message, etc.
              }

      })
      .catch((error: any) => {
        setIsAdding(false);
        if (error.response?.status === 401) {
          alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'ajout d'évenement");
        }
        console.error("Erreur lors de l'ajout d'évenement:", error);
      });
  
      } 
      else
      {
        setIsAdding(false);
      }
    } catch (error) {
      setIsAdding(false);
      console.error("Une erreur s'est produite :", error);
      alert("Une erreur s'est produite lors de la manipulation des événements.");
      return -1;
    }
  };

  const processModifVoyage = async () => {
    
    const verrouillageResultat = await verrouillageModif();

    if (verrouillageResultat === 1) {
      modifVoyage();
    }
  };

  processModifVoyage();
  }

        const moveCard = async (dragIndex: number, hoverIndex: number, draggedIdJournee: number | null, id_journee: number, index: number) => {

          const modifVoyage = async () => {
            try {
          if (draggedIdJournee !== null && id_journee !== null  && draggedIdJournee !== id_journee) {

            const updatedCategories = [...eventCategories];
            var indexDeb1 = eventCategories.findIndex(day => day.id_journee === draggedIdJournee);
            var indexDeb2 = eventCategories.findIndex(day => day.id_journee === id_journee);
    
            var indexGroupeNuit1=0;
            var indexGroupeNuit2=0;
            var nbAj=0;
            var nbSou=0;
            var indexFin1=0;
            var indexFin2=0;
            var numNuitDebAj=0;
            var numNuitFinAj=0;
            var numNuitDebSou=0;
            var numNuitFinSou=0;

            for(var i=0; i < groupedNights.length; i++)
            {
              for(var j=0; j < groupedNights[i].groupVilles.length; j++)
              {
                if(groupedNights[i].groupVilles[j].firstDayId === draggedIdJournee)
                {
                  indexFin1=indexDeb1+groupedNights[i].groupVilles[j].nights.length-1;
                  indexGroupeNuit1=i;
                }
                else if(groupedNights[i].groupVilles[j].firstDayId === id_journee)
                {
                  indexFin2=indexDeb2+groupedNights[i].groupVilles[j].nights.length-1;
                  indexGroupeNuit2=i;
                }
              }
            }
   if(indexGroupeNuit1 === indexGroupeNuit2)
            {
              if(indexDeb1 < indexDeb2)
              {
                numNuitDebAj=indexDeb1+1;
                numNuitFinAj=indexFin1+1;
                numNuitDebSou=indexFin1+2;
                numNuitFinSou=indexFin2+1;
                nbAj=numNuitFinSou-numNuitDebSou+1;
                nbSou=numNuitFinAj-numNuitDebAj+1;
              }
              else
              {
                numNuitDebAj=indexDeb2+1;
                numNuitFinAj=indexDeb1;
                numNuitDebSou=indexDeb1+1;
                numNuitFinSou=indexFin1+1;
                nbAj=numNuitFinSou-numNuitDebSou+1;
                nbSou=numNuitFinAj-numNuitDebAj+1;
              }
            }
            else if(indexGroupeNuit1 < indexGroupeNuit2)
            {
              numNuitDebAj=indexDeb1+1;
              numNuitFinAj=indexFin1+1;
              numNuitDebSou=indexFin1+2;
              numNuitFinSou=indexDeb2;
              nbAj=numNuitFinSou-numNuitDebSou+1;
              nbSou=numNuitFinAj-numNuitDebAj+1;
            }
            else
            {
              numNuitDebAj=indexDeb2+1;
              numNuitFinAj=indexDeb1;
              numNuitDebSou=indexDeb1+1;
              numNuitFinSou=indexFin1+1;
              nbAj=numNuitFinSou-numNuitDebSou+1;
              nbSou=numNuitFinAj-numNuitDebAj+1;
            }

          
            if(nbAj !== 0 && nbSou !== 0 && numNuitDebAj !== 0 && numNuitFinAj !== 0 && numNuitDebSou !== 0 && numNuitFinSou !== 0)
            {
                voyageService.echangerNuits(voyageKey, {
                  nbAj,
                  nbSou,
                  numNuitDebAj,
                  numNuitDebSou,
                  numNuitFinAj,
                  numNuitFinSou
                })
                .then((response: any) => {
                   for(var i=numNuitDebAj-1; i<=numNuitFinSou-1; i++)
                    {
                      var dateJournee=eventCategories[i].date_journee.split(" ")[1];
            
                      if(i < numNuitDebSou-1)
                      {
                        eventCategories[i].date_journee=addDays(dateJournee, nbAj);
                        eventCategories[i].num_jour=eventCategories[i].num_jour+nbAj;
                      }
                      else
                      {
                        eventCategories[i].date_journee=subtractDays(dateJournee, nbSou);
                        eventCategories[i].num_jour=eventCategories[i].num_jour-nbSou;
                      }
                    }
            
                    const range1 = eventCategories.slice(numNuitDebAj-1, numNuitFinAj);
                    const range2 = eventCategories.slice(numNuitDebSou-1, numNuitFinSou);
            
               
                    const mergedArray = [...range2, ...range1];
            
                    var j=0;
                    for(var i=numNuitDebAj-1; i<=numNuitFinSou-1; i++)
                    {
                      eventCategories[i]=mergedArray[j];
                      j++
                    }
            
                    setEventCategories([...eventCategories]);
                })
                .catch((error: any) => {
            
                  if (error.response?.status === 401) {
                    alert("Erreur lors de l'échange, vous n'êtes pas le créateur du voyage.")
                    navigate('/');
                  }
                  else
                  {
                    alert("Erreur lors de l'échange");
                  }
                })
            }
            else
            {
              alert("Erreur, lors de l'échange. Veuillez actualiser la page.")
            }
        }
      } catch (error) {
        console.error(error);
        alert("Erreur lors de la modification !");
      }
    };

    const processModifVoyage = async () => {
      
      const verrouillageResultat = await verrouillageModif();
  
      if (verrouillageResultat === 1) {
        modifVoyage();
      }
    };

    processModifVoyage();
      };

function addDays(dateString: string, daysToAdd: number): string {
  // Split the input date string to get day, month, and year
  const [day, month, year] = dateString.split("/");

  // Create a new Date object
  const date = new Date(`${year}-${month}-${day}`);

  // Get the current timestamp and add the required number of days
  const newTimestamp = date.getTime() + (daysToAdd * 24 * 60 * 60 * 1000);

  // Create a new Date from the updated timestamp
  const newDate = new Date(newTimestamp);

  // Get the name of the day in French
  const options = { weekday: 'long' } as const;
  let nomJour = newDate.toLocaleDateString('fr-FR', options);

  // Capitalize the first letter of nomJour
  nomJour = nomJour.charAt(0).toUpperCase() + nomJour.slice(1);

  // Format the date back to dd/mm/yyyy
  const newDay = String(newDate.getDate()).padStart(2, '0'); // Ensure two digits for day
  const newMonth = String(newDate.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month (months are 0-based)
  const newYear = newDate.getFullYear(); // Get the year

  // Return the formatted date with day name
  return `${nomJour} ${newDay}/${newMonth}/${newYear}`;
}

function subtractDays(dateString: string, daysToSubtract: number): string {
  // Split the input date string to get day, month, and year
  const [day, month, year] = dateString.split("/");

  // Create a new Date object
  const date = new Date(`${year}-${month}-${day}`);

  // Get the current timestamp and subtract the required number of days
  const newTimestamp = date.getTime() - (daysToSubtract * 24 * 60 * 60 * 1000);

  // Create a new Date from the updated timestamp
  const newDate = new Date(newTimestamp);

  // Get the name of the day in French
  const options = { weekday: 'long' } as const;
  let nomJour = newDate.toLocaleDateString('fr-FR', options);

  // Capitalize the first letter of nomJour
  nomJour = nomJour.charAt(0).toUpperCase() + nomJour.slice(1);

  // Format the date back to dd/mm/yyyy
  const newDay = String(newDate.getDate()).padStart(2, '0'); // Ensure two digits for day
  const newMonth = String(newDate.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month (months are 0-based)
  const newYear = newDate.getFullYear(); // Get the year

  // Return the formatted date with day name
  return `${nomJour} ${newDay}/${newMonth}/${newYear}`;
}

const echangerNuits = async (idJournee1: number, idJournee2: number, indexGroupe2: number) => {
  const modifVoyage = async () => {
    try {
  var nbNuitsGroupe2=0;
  for(var i=0; i< groupedNights[indexGroupe2].groupVilles.length; i++)
  {
    nbNuitsGroupe2+=groupedNights[indexGroupe2].groupVilles[i].nights.length;
  }


  const indexDeb1 = eventCategories.findIndex(day => day.id_journee === idJournee1);
  const indexDeb2 = eventCategories.findIndex(day => day.id_journee === idJournee2);
  const indexFin1 = indexDeb2-1;
  const indexFin2 = indexDeb2+nbNuitsGroupe2-1;
  const nbNuitsGroupe1=indexFin1-indexDeb1+1;

  // Swap the days in the array
  if (indexDeb1 !== -1 && indexFin1 !== -1 && indexDeb2 !== -1) 
  {
    voyageService.echangerNuits(voyageKey, {
      nbAj:nbNuitsGroupe2,
      nbSou:nbNuitsGroupe1,
      numNuitDebAj:indexDeb1+1,
      numNuitDebSou:indexDeb2+1,
      numNuitFinAj:indexFin1+1,
      numNuitFinSou:indexFin2+1
    })
    .then((response: any) => {
     
        for(var i=indexDeb1; i<=indexFin2; i++)
        {
          var dateJournee=eventCategories[i].date_journee.split(" ")[1];

          if(i < indexDeb2)
          {
            eventCategories[i].date_journee=addDays(dateJournee, nbNuitsGroupe2);
            eventCategories[i].num_jour=eventCategories[i].num_jour+nbNuitsGroupe2;
          }
          else
          {
            eventCategories[i].date_journee=subtractDays(dateJournee, nbNuitsGroupe1);
            eventCategories[i].num_jour=eventCategories[i].num_jour-nbNuitsGroupe1;
          }
        }

        const range1 = eventCategories.slice(indexDeb1, indexFin1 + 1);
        const range2 = eventCategories.slice(indexDeb2, indexFin2 + 1);
        const mergedArray = [...range2, ...range1];

        var j=0;
        for(var i=indexDeb1; i<=indexFin2; i++)
        {
          eventCategories[i]=mergedArray[j];
          j++
        }

        setEventCategories([...eventCategories]);
    })
    .catch((error: any) => {

      if (error.response?.status === 401) {
        alert("Erreur lors de l'échange, vous n'êtes pas le créateur du voyage.")
        navigate('/');
      }
      else
      {
        alert("Erreur lors de l'échange");
      }
    })
  }
  else
  {
    alert("Erreur, lors de l'échange. Veuillez actualiser la page.")
  }
} catch (error) {
  console.error(error);
  alert("Erreur lors de la modification !");
}
};

const processModifVoyage = async () => {

const verrouillageResultat = await verrouillageModif();

if (verrouillageResultat === 1) {
  modifVoyage();
}
};

processModifVoyage();
}; 

const handlePublicClick = () => {
  if(window.confirm(
    t('alertVoyagePublic')
  ))
  {
    voyageService.modifierVoyage({
      id_voyage: voyageKey,
      visibilite: "prive"
  })
      .then((response) => {
      if (response.data.message !== undefined && response.data.message !== null && response.data.message === 'ok') {
          window.location.reload();

      } else {
          // Gérer le cas où idVoyage est 0 ou null
      }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          alert("Erreur lors du changement de visibilité, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors du changement de visiblité");
        }

      });

  }
}


const handlePriveClick = () => {
  if(username && username !== '' && username !== 'null')
  {
  if(window.confirm(
    t('alertVoyagePrive')
  ))
  {
    voyageService.modifierVoyage({
      id_voyage: voyageKey,
      visibilite: "public"
  })
      .then((response) => {
      if (response.data.message !== undefined && response.data.message !== null && response.data.message === 'ok') {
          window.location.reload();

      } else {
          // Gérer le cas où idVoyage est 0 ou null
      }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          alert("Erreur lors du changement de visibilité, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors du changement de visiblité");
        }

      });

  }
}
else
{
  alert("Pour rendre un voyage public, vous devez remplir un nom d'utilisateur dans votre profil.");
}
}

const handleConvertisseurClick = () => {
  if(etatVoyage !== 0)
  {
    openModalConvertisseur();
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}


const handleStatistiquesClick = async () => {
  if(etatVoyage !== 0)
  {
    try {
      
      const response = await voyageService.getStatistiques(voyageKey, emailPartage, codePartage);

      if(response.data.budget !== 0)
      {
        var budgetCal=Math.round(response.data.budget/nb_voyageurs/nbJours);
        setStatBudget(budgetCal.toString());
      }
      else
      {
        setStatBudget("0");
      }

      setStatNbEtapes(response.data.nbEtapes);
      setStatDistance(response.data.distance);
      setStatDistanceAvion(response.data.distanceAvion);
      setStatDistanceBateau(response.data.distanceBateau);
      setStatDistanceTrain(response.data.distanceTrain);
      setStatDistanceBus(response.data.distanceBus);
      setStatDistanceTaxi(response.data.distanceTaxi);
      setStatDistanceVoiture(response.data.distanceVoiture);
      setStatDistanceMoto(response.data.distanceMoto);
      setStatDistanceVelo(response.data.distanceVelo);
      setStatDistancePied(response.data.distancePied);
    
      
      openModalStatistiques();

    } catch (error: any) {
  
      if (error.response?.status === 401) {
        localStorage.removeItem('xsrfToken');
      }
      console.error("Error fetching data:", error);
      navigate('/');
    }
    
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

const handleModifResumeClick = async (idPays: string, firstDay: string, firstDayId: number, nuits: EventType[]) => {
  setFirstDayModif(firstDay);
  setFirstDayIdModif(firstDayId);
  setNuitsModif(nuits);
  setPaysModif(idPays);

  openModalModifResume();
}

const handleLieuxResumeClick = async (nuits: EventType[]) => {

    try {

      var listeLieuxVisites: string[] = [];
      var listeLieuxVisitesTemp="";

      nuits.forEach((nuit) => {
        eventCategories.forEach((day) => {
          if(day.date_journee && day.date_journee !== '')
          {
            listeLieuxVisitesTemp=day.date_journee.split(' ')[1] + " :";
          }

          day.evenements.forEach((evenement) => {
            if(evenement.typeEv === "L" && (evenement.type === null || evenement.type === ""))
            {
              if(evenement.libelle1 !== null && evenement.libelle1 !== "")
              {
                listeLieuxVisitesTemp+=" "+evenement.libelle1 + " - ";
              }
            }
            if(evenement.typeEv === "N")
            {
              if(evenement.id === nuit.id)
              {
                  console.log('listeLieuxVisitesTemp',listeLieuxVisitesTemp, listeLieuxVisitesTemp.length);
                  if((day.date_journee && day.date_journee !== '' && listeLieuxVisitesTemp.length === 12) || ((!day.date_journee || day.date_journee === '') && listeLieuxVisitesTemp.length === 0))
                  {
                    listeLieuxVisites.push(listeLieuxVisitesTemp + " Aucun lieu renseigné");
                  }
                  else
                  {
                    listeLieuxVisites.push(listeLieuxVisitesTemp.substring(0, listeLieuxVisitesTemp.length-3));
                  }
                  listeLieuxVisitesTemp="";
              }
              else
              {
                listeLieuxVisitesTemp="";
              }
            }
            
          });
        });
      });

      setLieuxVisites(listeLieuxVisites);
    
      openModalLieuxResume();

    } catch (error: any) {
  
      if (error.response?.status === 401) {
        localStorage.removeItem('xsrfToken');
      }
      console.error("Error fetching data:", error);
      navigate('/');
    }
}

const handleContenuBagageClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalContenuBagage(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

const handleIntegrationClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalIntegration(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}


const handlePartageClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalPartage(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

const handleImportClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalImport(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}


const handleExportClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalExport(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

  return (
    <DndProvider backend={HTML5Backend}>
  
  { (numDroit === 1 || numDroit === 0) ? (
  <div className={`flex items-center justify-between space-x-5 mt-0 mb-10 ${ visibilite === "public" ? "boutonsOutils" : ""}`}>
<div className="flex-grow"> {/* Cette division prendra tout l'espace disponible */}
    <div className="text-xxs md:text-xs text-center relative">
      <div>
        <button
          onClick={toggleModif}
          className={`w-10 h-6 rounded-full p-1 transition-colors ${
            isModif ? 'bg-rosetrip-500' : 'bg-gray-300'
          }`}
        >
          <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isModif ? 'translate-x-full' : ''}`} />
        </button>
      </div>
      <div className="pt-2">
        {isModif ? (
          <span>{t('modeModif')}</span> 
        ) : (
          <span>{t('modeConsult')}</span> 
        )}
        &nbsp;
      </div>
    </div>
  </div>

  <div className="flex space-x-5">
    <BtnOutils 
      onConvertisseurClick={handleConvertisseurClick}
      onStatistiquesClick={handleStatistiquesClick} 
      onContenuBagageClick={handleContenuBagageClick}
      onImportClick={handleImportClick}
      onExportClick={handleExportClick}
      visibilite={visibilite}
      onIntegrationClick={visibilite && visibilite === 'public' ? handleIntegrationClick : () => {}}
      onPartageClick={visibilite && visibilite === 'public' ? handlePartageClick : () => {}}
    />
    { etatVoyage !== 0 && (
      <>
        { visibilite === "public" ? (
          <>
          <ButtonSecondary 
          sizeClass="px-2 py-2 sm:px-2"
          fontSize = "text-sm sm:text-sm font-medium"
          onClick={handlePublicClick}>
            <i className="las la-lock-open text-md"></i>
            <span className="ml-1">Public</span>
          </ButtonSecondary>
          <div className="items-center flex">
            <BtnLikeVoyageIcon nbLikes={nbLikes} isLiked={isLiked} className="absolute z-[1]" onLikeClick={handleLikeClick} />
          </div>
        </>
        ) : (
          <>
          <ButtonSecondary 
          sizeClass="px-2 py-2 sm:px-2"
          fontSize = "text-sm sm:text-sm font-medium"
          onClick={(username && username !== '' && username !== 'null') ? handlePriveClick : () => alert("Pour rendre un voyage public, vous devez remplir un nom d'utilisateur dans votre profil.")}>
            <i className="las la-lock text-md"></i>
            <span className="ml-1">{t('prive')}</span>
          </ButtonSecondary>
          <div className="items-center flex">
          </div>
          </>
        )}
      </>
    )}
  </div>
</div>
)  :  visibilite === "public" ? (
  <div className={`flex items-center justify-between space-x-5 mt-0 mb-10 ${ visibilite === "public" ? "boutonsOutils" : ""}`}>
  <div className="flex-grow"> 
  </div>
    <div className="flex space-x-5 ">
            <ButtonSecondary onClick={handlePartageClick} 
            sizeClass="px-2 py-2 sm:px-2"
            fontSize = "text-sm sm:text-sm font-medium">
              <i className="las la-share-alt text-md"></i>
              <span className="ml-1">{t('partage')}</span>
            </ButtonSecondary>
            <div className="items-center flex">
              <BtnLikeVoyageIcon nbLikes={nbLikes} isLiked={isLiked} className="absolute  z-[1]" onLikeClick={handleLikeClick} />
            </div>
    </div>
  </div>
) : (null)}
{ numDroit === 1 && mailKo && (
  <div className="sticky texteGras items-center text-center space-x-5 mb-6 text-xs md:text-sm lg:text-sm xl:text-sm ">
    <p><i className='las la la-warning'></i> L'email utilisé pour créer votre <a href='/account'><u>compte</u></a> est erroné, veuillez <a href='/contact'><u>nous contacter</u></a> pour le rectifier !</p>
  </div>
)}
    { !isModif && groupedNights.length === 0 
    ? (<div className="mt-4 mb-20 text-sm text-center sticky">Aucune ville localisée n'est présente pour les éléments nuits</div>)
    :
    (<ItemsSlider title="" nomClass="item-slider-container">
    {groupedNights.map((group: any, index: number) => (
        <div key={index} className="text-center">
          <h2 className={`champJour ${(isModif && index >= groupedNights.length-1) || !isModif ? "mb-8" : ""} ${group.nom_pays === t('nuitsIncompletes') ? "couleurRose" : ""}`}>{group.nom_pays} {flags[group.pays] && <span>{flags[group.pays]}</span>}</h2>
          { isModif && index < groupedNights.length-1 && (
                      <div className="grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-5">
                        <div></div>
                        <div data-tooltip-id="myTooltip"
                              data-tooltip-content="Cliquez pour échanger l'ordre de visite des pays"
                              data-tooltip-place="top"
                              className="flecheEchange">
                                <i onClick={() => echangerNuits(group.groupVilles[0].firstDayId, groupedNights[index+1].groupVilles[0].firstDayId, index+1)}
                                   className="iconeTransport las la-exchange-alt"></i>
                        </div>
                        <Tooltip id="myTooltip" />
                      </div>
          )}
          {group.groupVilles.map((ville: any, villeIndex: number) => {
            const cardContent = (
              <CardNuitResume
                key={villeIndex}
                libelle={ville.libelle1}
                jour={ville.firstDay}
                nbNuits={ville.nights.length}
                image={ville.image}
                pays={group.pays}
                isModif={isModif}
                onLieuxResumeClick={!isModif ? () => handleLieuxResumeClick(ville.nights) : () => handleModifResumeClick(group.pays, ville.firstDay, ville.firstDayId, ville.nights)}
              />
            );

            return isModif ? (
              <DraggableCard
                key={`${index}-${villeIndex}`}
                index={index}
                id_journee={ville.firstDayId}
                eventCategories={eventCategories}
                groupedNights={groupedNights}
                onDrop={(dragIndex, hoverIndex, draggedIdJournee) => moveCard(dragIndex, hoverIndex, draggedIdJournee, ville.firstDayId, index)}
              >
                {cardContent}
              </DraggableCard>
            ) : (
              cardContent
            );
          })}
        </div>
      ))}
  </ItemsSlider>
  )}
  
{isModalOpen && (
        <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      {t('questionAjout')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        onClick={() => addEvenement("lieu")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-map-marker`}
                        ></i>
                        <span>{t('lieu')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("repas")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl la la-pizza-slice`}
                        ></i>
                        <span>{t('repas')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("trajet")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-plane`}
                        ></i>
                        <span>{t('trajet')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("location")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-car-alt`}
                        ></i>
                        <span>{t('location')}</span>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}
      
  

{isModalConvertisseurOpen && (
       <ConvertisseurMonnaie onConvertisseurCloseModal={closeModalConvertisseur} />
       )}

{isModalBookingOpen && (
       <ModalBooking
       onBookingCloseModal={closeModalBooking} 
       ville={lieu1}
       latitude={latitude1}
       longitude={longitude1}
       hebergement={lieu2}
       latitudeHeb={latitude2}
       longitudeHeb={longitude2}
       />
       )}

{isModalStatistiquesOpen && (
       <ModalStatistiques 
        onStatistiquesCloseModal={closeModalStatistiques} 
        nbEtapes={statNbEtapes}
        budget={statBudget}
        nbJours={nbJours}
        monnaie={monnaie}
        distance={statDistance}
        distanceAvion={statDistanceAvion}
        distanceBateau={statDistanceBateau}
        distanceTrain={statDistanceTrain}
        distanceBus={statDistanceBus}
        distanceTaxi={statDistanceTaxi}
        distanceVoiture={statDistanceVoiture}
        distanceMoto={statDistanceMoto}
        distanceVelo={statDistanceVelo}
        distancePied={statDistancePied}
        />
       )}

{isModalLieuxResumeOpen && (
       <ModalLieuxResume 
        onLieuxResumeCloseModal={closeModalLieuxResume} 
        lieux={lieuxVisites}
        />
       )}

{isModalModifResumeOpen && (
       <ModalModifResume 
        onModifResumeCloseModal={closeModalModifResume} 
        nuits={nuitsModif}
        date={firstDayModif}
        idDate={firstDayIdModif}
        listeNomPays={libelles_pays}
        listeIdPays={id_pays}
        pays={paysModif}
        voyageKey={voyageKey}
        onDisplayElementChange={handleElementChange}
        eventCategories={eventCategories}
        />
       )}

{isModalContenuBagageOpen && (
       <ModalContenuBagage 
       onContenuBagageCloseModal={closeModalContenuBagage} 
       idVoyage={voyageKey}
        />
       )}

{isModalIntegrationOpen && (
       <ModalIntegration 
       onIntegrationCloseModal={closeModalIntegration} 
       idVoyage={voyageKey}
       affichageBudget={affichageBudget}
        />
    )}

{isModalPartageOpen && (
       <ModalPartageRS  onClose={closeModalPartage}
       keyVoyage={Number(voyageKey)}
       onIntegrationClick={visibilite && visibilite === 'public' ? handleIntegrationClick : () => {}}
        />
    )}

{isModalImportOpen && (
       <ModalImport 
       onImportCloseModal={closeModalImport} 
       idVoyage={voyageKey}
       nbJours={nbJours}
       eventCategories={eventCategories}
       onAddEvenement={handleAddEvenement as OnAddEvenementType}
        />
    )}


{isModalExportOpen && (
       <ModalExport 
       onExportCloseModal={closeModalExport} 
       idVoyage={voyageKey}
        />
       )}

       
{isAdding && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('ajoutEnCours')}
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}

  </DndProvider>
  );
};

export default PlanificateurResume;
